<template>
  <div class="news">
        <Navigation></Navigation> 
        <div class="bg"></div>
        <div class="mb_news_bg"></div>
        <img src="../assets/images/back_btn.png" alt="" class="back_btn" @click="goback">
        <div class="news_content">
            <img src="../assets/images/msg_btn.png" alt="" class="msg_btn" @click="goMsg">
            <div class="news_tags">
                <h3 class="title">{{title}}</h3>
                <div class="time">
                <span class="tag">{{tagName}}</span>
                <span>{{time}}</span></div>
                <div class="article quill-editor" v-html="content">
                </div>
            </div>
        </div>
        <div class="mask" v-show="isMsg">
            <div class="msg">
                <img src="../assets/images/close_role.png" alt="" class="close_msg" @click="isMsg=false">
                <div class="msg_write">
                    <div class="name">
                        <span>お名前：</span>
                        <input type="text" v-model="username" placeholder="お名前を入力してください">
                        <span class="warn" v-show="isWarnName">お名前を入力してください</span>
                    </div>
                    <div class="comment">
                        <div class="tag">コメント：</div>
                        <textarea name="" id="" cols="30" rows="10" v-model="comments" placeholder="コメントを入力してください"></textarea>
                    </div>
                    <div class="send_btn">
                        <span class="warn" v-show="isWarnContent">コメントを入力してください</span>
                        <img src="../assets/images/send_btn.png" alt=""  @click="addMsg">
                    </div>
                </div>
                <div class="msg_list">
                    <ul>
                        <li v-for="(item,index) in massageList" :key="index">
                            <div class="name">
                                <span>お名前：</span>
                                <span>{{item.name}}</span>
                                <span class="date">{{item.date}}</span>
                                <div class="tag">コメント：</div>
                                <div class="info">{{item.content}}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import Navigation from '../components/navigation'
import api from '@/api'
import getQueryString from '@/methods/getQueryString'
import getNowTime from '@/methods/getNowTime'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: 'news',
    components:{
      Navigation
  },
  data(){
      return{
          title:'',
          tagName:'',
          time:'',
          content:'',
          username:'',
          comments:'',
          newsId:null,
          tagId:'',
          isMsg:false,
          msgList:[],
          isWarnName:false,
          isWarnContent:false
      }
    },
    mounted(){
            this.newsId = getQueryString("newsId");
            this.tagId = getQueryString("tagId") || 1;
            this.getNewsDetail()
            this.getView()
    },
    computed:{
        massageList(){
            return this.msgList.map((e,i)=>{
                e.date = getNowTime(e.c_time)
                return e
            })
        }
    },
    methods:{
        goback:function(){
            this.$router.push(`/news?tagId=${this.tagId}`)
        },
        // 浏览
        getView(){
            api.view(JSON.stringify({
                type:'news',
                n_id:this.newsId
            }))
        },
        // 获取资讯详情
        getNewsDetail(){
            api.getNewsDetail(JSON.stringify({
                n_id:this.newsId
            }))
            .then((res) => {
                let resData = res.data
                if(resData.code === 200){
                    this.title = resData.data.title
                    this.tagName = resData.data.tag
                    this.time = getNowTime(resData.data.c_time)
                    this.content = resData.data.content;
                }
            })
        },
        goMsg(){
            this.isMsg = true
            this.username = ''
            this.comments = ''
            this.getMsgList()
        },
        getMsgList(){
            api.getMessageList(JSON.stringify({
                 n_id:this.newsId
            }))
            .then((res) => {
                let resData = res.data
                if(resData.code === 200){
                    this.msgList = resData.data
                    
                }
            })
        },
        addMsg(){
            if(!this.username){
                this.isWarnName = true
                return false
            }
            this.isWarnName = false
            if(!this.comments){
                this.isWarnContent = true
                return false
            }
            this.isWarnContent = false
            api.addMessage(JSON.stringify({
                 n_id:this.newsId,
                 name:this.username,
                 content:this.comments
            }))
            .then((res) => {
                let resData = res.data
                if(resData.code === 200){
                    this.username = ''
                    this.comments = ''
                    this.getMsgList()
                }
            })
        }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    
    .news{
        width: 100%;
        height: 100%;
        text-align: left;
    }
     .bg{
        background: url('../assets/images/bg.jpg') no-repeat;
        background-attachment: fixed;
        background-size: cover;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .mb_news_bg{
        background: url('../assets/images/mb_news_bg.jpg') no-repeat;
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        display: none;
    }
    .back_btn{
        width:232px;
        margin-top: 150px;
        margin-left: 80px;
        cursor: pointer;
    }
    .news_content{
        position: relative;
        top: -80px;
        left: 50%;
        transform: translateX(-50%); 
        width: 2500px;
        height: 1500px;
        overflow: hidden;
        background: url('../assets/images/news_content.png') no-repeat;
        background-size: 100% 100%;
        .msg_btn{
            position: absolute;
            width: 300px;
            top: 50px;
            right: 200px;
            cursor: pointer;
        }
        .news_tags{
            position: absolute;
            width: 65%;
            height: 1000px;
            top: 250px;
            left: 50%;
            transform: translateX(-50%);
            color: #fff;
            overflow: auto;
            padding-top: 50px;
                .title{
                    font-weight: 700;
                    font-size: 60px;
                    text-align: center;
                    vertical-align: sub;
                }
                .time{
                    font-size: 30px;
                    text-align: right;
                    .tag{
                        display: inline-block;
                        margin-right: 20px;
                    }
                }
                .article{
                    font-size: 50px;
                    text-align: left;
                    margin-top: .2rem;
                    overflow: auto;
                   ol,li,img{
                        width: 50% !important;
                    }
                    p{
                        color: #fff
                    }
                }
                
            }
    }
    .msg{
        background: url('../assets/images/msg_box.png') no-repeat;
        background-size: 100% 100%;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 2400px;
        height: 1400px;
        padding: 100px;
        color: #000;
        .close_msg{
            position: absolute;
            right: 0;
            top: -50px;
            width: 150px;
            cursor: pointer;
        }
        .msg_write{
            text-align: left;
            margin-left: 200px;
        }
        .name{
                font-size: 50px;
                font-weight: 700;
                input{
                    width: 800px;
                    height: 90px;
                    font-size: 50px;
                    background: #fff;
                    padding: 20px 30px;
                    vertical-align: bottom;
                    border-radius: 20px;
                    &::placeholder{
                        font-size:40px;
                    }
                }
                .warn{
                    font-size: 40px;
                    font-weight: 500; 
                    margin-left: 20px;
                    color: red;
                }
        }
        .comment{
            margin-top: 10px;
            .tag{
                font-size: 50px;
                font-weight: 700;
            }
            textarea{
                width: 1800px;
                height: 260px;
                line-height: 50px;
                font-size: 40px;
                background: #fff;
                padding: 20px;
                font-weight: 500;
                vertical-align: bottom;
                border-radius: 20px;
            }
        }
        .send_btn{
            width: 1800px;
            text-align: right;
            img{
                width: 200px;
                margin-right: 50px;
                cursor: pointer;
            }
            span{
                font-size: 40px;
                font-weight: 500; 
                margin-left: 20px;
                color: red;
                float: left;
            }
        }
        .msg_list{
            width: 1800px;
            height: 570px;
            margin: 0 auto;
            overflow: auto;
            ul{
                li{
                    width: 100%;
                    height: 260px;
                    border-radius: 20px;
                    background: #fff;
                    padding: 20px;
                    overflow:auto;
                    margin-bottom: 50px;
                    .info{
                        font-weight: 500;
                    }
                    .date{
                        float: right;
                    }
                }
            }
        }
    }
    .mask{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.7);
        z-index: 999;
    }
    
// 移动端
@media screen and (max-width: 750px) {
    .back_btn{
        width: 400px;
        margin-top: 400px;
    }
    .bg{
        display: none;
    }
    .mb_news_bg{
        display: block;
    }
    .news_content{
       position: relative;
        width: 90%;
        height: 5000px;
        background: url('../assets/images/mb_news_content.png') no-repeat;
        background-size: 100% 100%;
         .msg_btn{
            width: 800px;
            top: 150px;
        }
        .news_tags{
            height: 4000px;
            top: 600px;
            .title{
                font-size: 150px;
            }
            .time{
                font-size: 100px;
            }
            .tag{
                font-size: 100px;
            }
            .article{
                font-size: 120px;
                p img{
                    width: 100% !important;
                }
            }
        }
    }
    .msg{
        background: url('../assets/images/mb_msg_box.png') no-repeat;
        background-size: 100% 100%;
        width: 3200px;
        height: 5000px;
        padding: 200px 50px;
        .close_msg{
            top: -50px;
            width: 350px;
        }
        .msg_write{
            margin-left: 200px;
        }
        .name{
                font-size: 150px;
                font-weight: 600;
                input{
                    width: 1800px;
                    height: 280px;
                    font-size: 150px;
                    padding: 65px 30px;
                    border-radius: 50px;
                    &::placeholder{
                        font-size:120px;
                    }
                }
                .warn{
                    display: block;
                    font-size: 100px;
                    margin-left: 600px;
                }
        }
        .comment{
            .tag{
                font-size: 150px;
            }
            textarea{
                width: 2680px;
                height: 800px;
                line-height: 150px;
                font-size: 120px;
                padding: 50px;
                border-radius: 50px;
            }
        }
        .send_btn{
            width: 2680px;
            img{
                width: 420px;
                margin-right: 50px;
            }
            span{
                font-size: 100px;
            }
        }
        .msg_list{
            width: 2680px;
            height: 2700px;
            border-radius: 50px;
            ul{
                li{
                    height: 700px;
                    border-radius: 50px;
                    margin-bottom: 100px;
                }
            }
        }
    }
}
</style>

//获取当前时间，格式YYYY-MM-DD
export default function getNowFormatDate(str) {
    let date;
    if(!str){
        date = new Date()
    }else{
        date = new Date(parseInt(str));
    }
    let seperator1 = "-";
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    let currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
}
import Vue from 'vue'
import Router from 'vue-router'
import homeIndex from './views/home.vue'
import newsIndex from './views/news.vue'
import rolesIndex from './views/roles.vue'
import newsDetailIndex from './components/newsDetail.vue'
Vue.use(Router)

export default new Router({
  // mode:"history",
  routes: [
    {
      path: '/',
      name: 'home',
      component: homeIndex,
      meta: {
        keepAlive: false, //此组件不需要被缓存
      }
    },
    {
      path: '/news',
      name: 'news',
      component: newsIndex
    },
    {
      path: '/roles',
      name: 'roles',
      component: rolesIndex
    },
    {
      path: '/newsDetail',
      name: 'newsDetail',
      component: newsDetailIndex
    },
  ]
})

import axios from 'axios'
import Cookie from 'js-cookie'
// Cookie.set('cookie','G_ENABLED_IDPS=google; JSESSIONID=93F83851693A2C6839E8F18F82A98C1C; SERVERID=ac39a5d884cc8b22293a14f48053cd08|1543765789|1543765774')
// 请求默认参数
axios.defaults.withCredentials = true
let http = axios.create({
//   baseURL:'http:0.0.0.0:8888/',
  timeout: 1000000,
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded'
  }
})

export default {
	// 获取资讯
	getNewsList(params) {
		return http.post('/api/getNewsList', params)
	},
  // 获取资讯详情
	getNewsDetail(params) {
		return http.post('/api/getNewsDetail', params)
	},
	// 获取留言
	getMessageList(params) {
		return http.post('/api/getMessageList', params)
	},
	// 添加留言
	addMessage(params) {
		return http.post('/api/addMessage', params)
	},
	// 浏览量
	view(params) {
		return http.post('/api/view', params)
	},
	// 获取漫画
	getCartoonList(params) {
		return http.post('/api/getCartoonList', params)
	},
	// 查看漫画
	getCartoonDetail(params) {
		return http.post('/api/getCartoonDetail', params)
	},
}

<template>
  <div class="home">
    <Navigation></Navigation>
    <div class="section1 section">
      <img src="../assets/images/bg1.jpg" alt="" class="bg" />
      <video
        src="https://dl.ckhdvt.com/vietnam-web/media/video.mp4"
        muted
        class="video"
        id="video"
        webkit-playsinline="true"
        playsinline="true"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        x-webkit-airplay="allow"
        x5-video-orientation="portraint"
        style="object-fit: fill"
      >
        >
      </video>
      <transition enter-active-class="animated fadeIn">
        <div class="sky" v-show="isSky">
          <div class="clouds_one"></div>
          <div class="clouds_two"></div>
          <div class="clouds_three"></div>
        </div>
      </transition>
    </div>
    <div class="section2 section">
      <img src="../assets/images/bg2.jpg" alt="" class="bg" />
      <!-- <img src="../assets/images/bg3.jpg" alt="" class="bg"> -->
      <img src="../assets/images/mb_bg.jpg" alt="" class="mb_bg" />
      <div class="frist">
        <div class="left">
          <!-- <div class="download">
                    <a href="https://itunes.apple.com/app/id1448024270" target="_blank" class="apple">
                        <img src="../assets/images/apple.png" alt="">
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.szckhd.jwgly.azvn" target="_blank"  class="android">
                        <img src="../assets/images/android.png" alt="">
                    </a> -->
          <!-- <a href="http://dl.goitaquanlaogia.com/comp/jwglyvn_google_googleplay_online_travellet_201908021518.apk" class="apk">
                        <img src="../assets/images/apk.png" alt="">
                    </a> -->
          <!-- </div> -->
          <!-- <div class="fb">
            <img src="../assets/images/fb.png" alt="" class="title" />
            <div
              class="reel fb_reel"
              v-bind:class="{ reel_active: isReelActive }"
            >
              <div class="list">
                <div
                  class="fb-page"
                  data-href="https://www.facebook.com/goitaquanlaogia/"
                  data-tabs="timeline"
                  data-small-header="true"
                  data-width="500"
                  data-height="550"
                  data-adapt-container-width="true"
                  data-hide-cover="false"
                  data-show-facepile="true"
                ></div>
              </div>
            </div>
          </div> -->

          <div class="news">
            <img src="../assets/images/news_title.png" alt="" class="title" />
            <div class="reel" v-bind:class="{ reel_active: isReelActive }">
              <!-- <ul class="news_tags">
                <li
                  v-for="(item, index) in newsList"
                  :key="index"
                  @click="goDetail(item)"
                >
                  <span class="tag">{{ item.tag }}</span>
                  <span class="title">{{ item.title }}</span>
                  <span class="time">{{ getNowTime(item.c_time) }}</span>
                </li>
              </ul>
              <div class="news_page">
                <img
                  src="../assets/images/role_introduce_front.png"
                  class="prev_btn"
                  @click="goPage('prev')"
                />
                <img
                  src="../assets/images/role_introduce_back.png"
                  class="back_btn"
                  @click="goPage"
                />
              </div> -->
              <div class="content">
                <p class="title">
                  THÔNG BÁO DỪNG PHÁT HÀNH SẢN PHẨM TA LÀ QUAN LÃO GIA - 100D
                </p>
                <p>
                  LỜI ĐẦU TIÊN, BAN ĐIỀU HÀNH XIN GỬI LỜI CẢM ƠN CHÂN THÀNH ĐẾN
                  TẤT CẢ CÁC ĐỒNG ĐẠO ĐÃ ĐỒNG HÀNH CÙNG TA LÀ QUAN LÃO GIA -
                  100D SUỐT THỜI GIAN QUA.
                </p>
                <p>
                  CÙNG TA LÀ QUAN LÃO GIA - 100D, CÁC ĐỒNG ĐẠO ĐÃ CÓ ĐƯỢC NHỮNG
                  TRẢI NGHIỆM VUI VẺ NHẤT, TỪ NHỮNG TRẬN THI ĐẤU LIÊN SERVER MỖI
                  TỐI HAY NHỮNG LÚC CÙNG ĐI HOẠT ĐỘNG VỚI NHAU, VÀ NHỮNG LÚC
                  CÙNG NHAU SĂN BOSS... TUY NHIÊN, VÌ NHỮNG LÝ DO KHÁCH QUAN LẪN
                  CHỦ QUAN, BAN ĐIỀU HÀNH RẤT LẤY LÀM TIẾC PHẢI THÔNG BÁO VỀ
                  VIỆC SẼ NGỪNG PHÁT HÀNH TA LÀ QUAN LÃO GIA - 100D KỂ TỪ NGÀY
                  29/05/2022 SẮP TỚI. RẤT MONG TẤT CẢ CÁC ĐỒNG ĐẠO ĐÃ TỪNG GẮN
                  BÓ CÙNG TA LÀ QUAN LÃO GIA - 100D THÔNG CẢM.
                </p>
                <p>LỊCH TRÌNH CỤ THỂ</p>
                <p>
                  NGÀY 23/04/2022: ĐÓNG HỆ THỐNG PAYMENT GAME TA LÀ QUAN LÃO GIA
                  - 100D BẮT ĐẦU TỪ 00:00 NGÀY 23/04/2022.
                </p>
                <p>
                  NGÀY 29/04/2022: ĐÓNG TOÀN BỘ MÁY CHỦ GAME, CHÍNH THỨC NGỪNG
                  PHÁT HÀNH TA LÀ QUAN LÃO GIA - 100D.
                </p>
                <p>XIN KÍNH CHÀO TẠM BIỆT.</p>
                <p>
                  MỌI Ý KIẾN THẮC MẮC VÀ CÁC HỖ TRỢ LIÊN QUAN ĐẾN VIỆC NGỪNG
                  CUNG CẤP SẢN PHẨM TA LÀ QUAN LÃO GIA - 100D, ĐỒNG ĐẠO VUI LÒNG
                  GỬI VỀ HỖ TRỢ KHÁCH HÀNG HOẶC PHẢN HỒI TRỰC TIẾP VỀ TRANG CHỦ
                  CŨNG NHƯ THƯ ĐIỆN TỬ.
                </p>
                <p>CHÚNG TÔI SẼ HỖ TRỢ XỬ LÝ TRONG THỜI GIAN NHANH NHẤT.</p>
                <p>CHÚNG TÔI SẼ HỖ TRỢ XỬ LÝ TRONG THỜI GIAN NHANH NHẤT.</p>
              </div>
            </div>
          </div>
          <div class="introduce">
            <img src="../assets/images/introduce.png" alt="" class="title" />
            <div class="reel" v-bind:class="{ reel_active: isReelActive }">
              <div class="content">
                <p>
                  Xuyên không về đời Thanh, bạn tuy chỉ là một thứ dân tầm
                  thường nhưng lại bị cuốn vào một cuộc chiến tranh quyền đoạt
                  vị. Liệu bạn có thể là người chiến thắng cuối cùng, đoạt được
                  Vương quyền? Từ đây trận chiến toàn sever tranh đoạt Ngai vàng
                  được chờ mong lâu nay sẽ được phát động! Toàn quân tham chiến,
                  chỉ có bạn mới xứng đáng được xưng là Hoàng Đế!
                </p>
                <p>
                  Ta Là Quan Lão Gia là một game mobile lấy “Quan đấu Cung đình”
                  làm đề tài chính, nội dung của Game chủ yếu xoay quanh cuộc
                  sống của nhân vật Quan Lão Gia, từ khi Quan Lão Gia đèn sách
                  mười năm, khoa cử, đỗ đạt tới khi lấy vợ sinh con, trừ gian
                  giệt ác, thăng quan tiến chức, phong Vương xưng Đế.
                </p>
                <p>
                  Game có hệ thống nhân vật vô cùng đồ sộ gồm cả trăm Môn Khách
                  là những vị Thanh quan đại thần và những nhân vật lịch sử nổi
                  danh kim cổ cùng mấy chục Hồng Nhan khuynh quốc khuynh thành
                  sẽ cùng tụ hội trong game.
                </p>
                <p>
                  Đến với Ta Là Quan Lão Gia bạn sẽ trở thành đạo diễn cho một
                  bộ phim truyền hình dài tập mà bạn là nhân vật chính!!!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="second">
        <div class="roles">
          <div v-for="(item, index) in roles" :key="index">
            <div>
              <img
                :src="item.img"
                alt=""
                class="role_img"
                @click="goRoles(item.key)"
              />
              <!-- <img :src="item.img_hover" alt="" class="role_img" v-show="active===index"> -->
            </div>
            <!-- <img :src="item.img_txt" alt="" class="role_txt" @click="goRoles(index)"> -->
          </div>
          <img
            src="../assets/images/role_cloud.png"
            alt=""
            class="role_cloud"
          />
        </div>
      </div>
    </div>
    <div class="section3 section">
      <img src="../assets/images/bg4.jpg" alt="" class="bg" />
      <div class="three">
        <!-- <a
          href="https://itunes.apple.com/app/id1448024270"
          target="_blank"
          class="apple"
        >
          <img src="../assets/images/apple.png" alt="" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.szckhd.jwgly.azvn"
          target="_blank"
          class="android"
        >
          <img src="../assets/images/android.png" alt="" />
        </a> -->
        <!-- <a href="http://dl.goitaquanlaogia.com/comp/jwglyvn_google_googleplay_online_travellet_201908021518.apk" class="apk">
                <img src="../assets/images/apk.png" alt="">
            </a> -->
      </div>
    </div>
    <div class="footer">
      <img src="../assets/images/footer.jpg" alt="" class="footer_bg" />
      <img src="../assets/images/footer.jpg" alt="" class="mb_footer_bg" />
      <a class="user_btn" href="https://100do.com.vn/dieu-khoan/">
        <img src="../assets/images/100d.png" alt="" />
      </a>
      <!-- <img src="../assets/images/logo_.png" alt="" class="logo_"> -->
    </div>
    <div class="pro-pop" v-show="isActivePro || isActiveUser">
      <div class="img">
        <img
          src="../assets/images/protocol_box.png"
          alt=""
          draggable="false"
          class="border protocol_box"
        />
        <img
          src="../assets/images/mb_protocol_box.png"
          alt=""
          draggable="false"
          class="border mb_protocol_box"
        />
        <div class="title" v-show="isActiveUser">
          ĐIỀU KHOẢN THỎA THUẬN DỊCH VỤ CHUANG COOL ENTERTAINMENT
        </div>
        <img
          src="../assets/images/protocol_close.png"
          alt=""
          class="close"
          @click="
            isActivePro = false;
            isActiveUser = false;
          "
        />
        <div class="text">
          <div class="user" v-show="isActiveUser">
            <h3>I. ĐỊNH NGHĨA VÀ MÔ TẢ DỊCH VỤ:</h3>
            <h4>1. Điều khoản Thỏa thuận:</h4>
            <p>
              Chuang Cool mời quý khách tham gia sử dụng các dịch vụ trò chơi
              trực tuyến  trên mobile (gọi tắt là Dịch vụ) trên cơ sở quý khách
              đã đồng ý với mọi điều khoản và điều kiện nêu trong Thỏa Thuận
              Dịch Vụ Chuang Cool (gọi tắt là TTDV Chuang Cool). Để xác nhận
              đồng ý, quý khách phải nhấn nút “Đồng Ý” ở cuối quá trình đăng ký
              tài khoản sau khi đã đọc và hiểu các điều khoản quy định trong
              TTDV CHUANG COOL.
            </p>
            <p>
              Quý Khách hàng cần đọc và đồng ý với tất các các quy định trong
              TTDV Chuang Cool trước khi sử dụng Dịch vụ để hiểu về quyền lợi và
              nghĩa vụ của Quý Khách hàng khi sử dụng Dịch vụ. Khi đã sử
              dụng Dịch vụ, có nghĩa là Quý Khách hàng đã hoàn toàn tự nguyện
              đồng ý với tất cả các quy định trong TTDV CHUANG COOL.
            </p>
            <p>
              TTDV CHUANG COOL bao gồm toàn bộ sự thoả thuận giữa Chuang Cool và
              Người sử dụng. TTDV Chuang Cool không thay cho bất kỳ điều khoản
              bổ sung nào mà Người sử dụng đã được thông báo bởi Chuang Cool và
              nó được áp dụng khi sử dụng nội dung hay dịch vụ của bên thứ ba.
              Chuang Cool được toàn quyền sửa đổi lại thỏa thuận này vào bất cứ
              lúc nào, và việc sửa đổi sẽ có hiệu lực sau khi các sửa đổi được
              thông báo trên website chính thức của Chuang Cool. Người sử dụng
              có thể xem lại TTDV Chuang Cool bằng cách truy cập vào Dịch vụ,
              tang web theo định kỳ để biết được những nội dung được sửa đổi.
              Nếu bất kỳ sự sửa đổi nào không được Người sử dụng chấp nhận,
              Người sử dụng có thể chấm dứt TTDV Chuang Cool.Tiếp tục sử dụng
              những Dịch vụ sau sửa đổi được thông báo sẽ được xem là đồng ý
              chấp nhận tất cả những sửa đổi đó.
            </p>
            <h4>2. Định nghĩa:</h4>
            <p>
              2.1. “Tài khoản Chuang Cool ID”: là tài khoản để truy cập sử dụng
              dịch vụ chơi game của Chuang Cool nói riêng và các sản phẩm dịch
              vụ khác của Chuang Cool nói chung.
            </p>
            <p>
              Chuang Cool: là đơn vị trực thuộc công ty cổ phần Chuang Cool
              entertainment . Chuang Cool là đơn vị cung cấp dịch vụ game online
              và sử dụng tài khoản Chuang Cool ID để chơi game.
            </p>
            <p>
              2.2. “Chủ tài khoản”: là người sở hữu tài khoản Chuang Cool ID
            </p>
            <p>
              2.3. “Dịch vụ trò chơi trực tuyến Chuang Cool (gọi tắt là dịch
              vụ)”: là các sản phẩm game online trên mobile do Chuang Cool cung
              cấp.
            </p>
            <p>
              Dịch vụ bao gồm các sản phẩm game được cung cấp thông tin trên
              trang chủ http://goitaquanlaogia.com, người sử dụng có thể chơi
              game online trên máy tính (PC) và tải game về Smartphone
            </p>
            <p>
              2.4. “Chuang Cool Entertainment”: là đơn vị cung cấp dịch vụ trò
              chơi trực tuyến
            </p>
            <p>
              2.5. “Chuang Cool ID”: là mã số được Chuang Cool cung cấp cho từng
              người chơi khi sử dụng Tài khoản Chuang Cool ID chơi game. Mỗi
              Chuang Cool ID sẽ được cấp 01 mã Chuang Cool ID duy nhất xuyên
              suốt quá trình chơi game, Chủ tài khoản có thể đổi Chuang Cool ID
              nhưng Chuang Cool ID chơi game của tài khoản là không đổi.
            </p>
            <p>
              2.6. “Người sử dụng”: là người chơi sử dụng dịch vụ trò chơi trực
              tuyến của Chuang Cool, là người sử hữu Chuang Cool ID, Game ID
              chơi game của Chuang Cool.
            </p>

            <h3>II. QUY ĐỊNH VỀ ĐĂNG KÝ, ĐĂNG NHẬP VÀ BẢO VỆ TÀI KHOẢN</h3>
            <h4>1. Đăng ký</h4>
            <p>
              - Để sử dụng dịch vụ quý khách cần đăng ký Chuang Cool ID bằng
              cách truy cập game (mục đăng ký) và điền đầy đủ các thông vào mẫu
              đăng ký. Để sử dụng dịch vụ quý khách cần hoàn tất quá trình đăng
              ký và đồng ý với tất cả các điều khoản theo bản Thỏa thuận.
            </p>
            <p>
              - Mỗi người sử dụng dịch vụ game có 1 Chuang Cool ID riêng dùng để
              đăng nhập, sau đó khi chơi game sẽ được cấp Game ID, Khách hàng
              chơi game phải Tạo nhân vật và lựa chọn máy chủ chơi game để nhận
              diện mình trong game. Quý khách phải chịu hoàn toàn trách nhiệm
              cho tất cả các hoạt động được thực hiện thông qua tài khoản của
              mình. Quý khách phải tuân theo quy định đặt tên tài khoản, tên
              nhân vật, bang hội như sau:
            </p>
            <p>
              1.1. Không được đặt tên tài khoản, tên nhân vật, hoặc tên bang hội
              (nếu có…) …có nội dung vi phạm pháp luật, phản động, thuần phong
              mỹ tục, các nội dung có tính ám chỉ liên tưởng nhạy cảm, ngôn từ
              tục tĩu, lăng mạ người khác.
            </p>
            <p>
              1.2. Không được đặt tên tài khoản, tên nhân vật, hoặc tên bang hội
              (nếu có…) …có nội dung liên quan tới các tổ chức, chính quyền, tín
              ngưỡng, tôn giáo…của nhà nước Cộng hòa Xã hội Chủ nghĩa Việt Nam
            </p>
            <h4>2. Đăng nhập</h4>
            <p>
              - Quý khách sẽ dùng tài khoản Chuang Cool ID do mình đã đăng ký và
              đã có Game ID được cấp để đăng nhập chơi game.
            </p>
            <p>
              - Đối với một tài khoản Chuang Cool ID, người sử dụng sẽ có 1 mật
              khẩu để đăng nhập và đăng nhập được tất cả các dịch vụ sử dụng tài
              khoản Chuang Cool ID
            </p>
            <p>
              - Quý khách tự chịu trách nhiệm bảo vệ thông tin tài khoản của
              mình và phải thông báo ngay cho Chuang Cool nếu quý khách tin rằng
              thông tin tài khoản của mình bị xâm nhập. Chuang Cool không chịu
              trách nhiệm trước các trường hợp tài khoản bị xâm nhập do tài
              khoản bị lộ mật khẩu, và không chịu trách nhiệm xử lý các trường
              hợp khiếu nại về tài khoản nếu người sử dụng không cung cấp được
              các thông tin xác minh là chủ tài khoản.
            </p>
            <h4>3. Bảo vệ tài khoản</h4>
            <p>
              - Thông tin đăng ký tài khoản Chuang Cool ID gồm email (hoặc số
              điện thoại) và mật khẩu.
            </p>
            <p>
              - Các thông tin đăng ký bảo vệ tài khoản sẽ là các thông tin dùng
              để xác thực chủ tài khoản. Khi có phát sinh khiếu nại về tài khoản
              Chuang Cool sẽ hỗ trợ người sử dụng nào cung cấp được đầy đủ và
              chính xác nhất các thông tin đã đăng ký bảo vệ tài khoản.
            </p>
            <h3>III. QUYỀN HẠN VÀ TRÁCH NHIỆM</h3>
            <h4>1. Quyền của chủ tài khoản Chuang Cool ID</h4>
            <p>
              - Được quyền thay đổi tài khoản đăng nhập và thay đổi mật khẩu của
              tài khoản, thay đổi các thông tin bảo vệ tài khoản đã đăng ký.
            </p>
            <p>
              - Được quyền sử dụng tài khoản Chuang Cool ID đăng nhập và sử dụng
              tất cả các dịch vụ dùng tài khoản Chuang Cool ID nói chung và đăng
              nhập chơi game trực tuyến của Chuang Cool nói riêng.
            </p>
            <h4>2. Trách nhiệm của chủ tài khoản Chuang Cool ID</h4>
            <p>
              - Đăng ký tên tài khoản Chuang Cool ID theo quy định về đăng ký
              như trên.
            </p>
            <p>
              - Đăng ký đầy đủ thông tin cá nhân trung thực chính xác, đăng ký
              đầy đủ thông tin bảo vệ tài khoản để thể hiện sở hữu đối với tài
              khoản.
            </p>
            <p>
              - Xuất trình đầy đủ bằng chứng chứng minh là chủ tài khoản khi có
              yêu cầu từ Chuang Cool.
            </p>
            <p>
              - Chủ tài khoản tự chịu trách nhiệm bảo vệ thông tin tài khoản của
              mình bao gồm email/số điện thoại đăng ký làm Chuang Cool ID, số
              điện thoại bảo vệ, chứng minh thư nhân dân, thông tin giao dịch
              nạp vào tài khoản Chuang Cool ID…Và phải thông báo ngay cho Chuang
              Cool nếu quý khách tin rằng thông tin tài khoản của mình bị xâm
              nhập.
            </p>
            <h4>3. Quyền của Chuang Cool</h4>
            <p>
              - Chuang Cool có quyền tạm khóa hoặc khóa Vĩnh viễn tài khoản
              Chuang Cool ID nếu phát hiện Chuang Cool ID vi phạm điều khoản
              thỏa thuận dịch vụ.
            </p>
            <p>
              - Chuang Cool có quyền gián đoạn dịch vụ đôi lúc theo kế hoạch
              thường xuyên hoặc có thể không thông báo trước (do lỗi đột xuất)
              để bảo trì dịch vụ.
            </p>
            <p>
              - Quý khách đồng ý dịch vụ có thể bị gián đoạn vì lý do ngoài tầm
              kiểm soát của Chuang Cool, và Chuang Cool có thể không đảm bảo
              rằng quý khách sẽ có thể sử dụng Dịch vụ của quý khách lúc nào quý
              khách muốn khi xảy ra lỗi trên. Chuang Cool sẽ không chịu trách
              nhiệm đối với bất kỳ sự gián đoạn Dịch vụ nào, đối với việc chậm
              trễ hoặc không thực hiện được dịch vụ do các nguyên nhân khách
              quan xảy ra như: thiên tai, chiến tranh, do nhà cung cấp dịch vụ
              mạng, dịch vụ điện... gây gián đoạn dịch vụ.
            </p>
            <p>
              - Chuang Cool có quyền thay đổi nội dung của TTDV Chuang Cool này
              mà không cần thông báo trước với quý khách. Tuy nhiên, sau khi nội
              dung được thay đổi, điều chỉnh, Chuang Cool sẽ có thông báo tới
              các khách hàng đã và đang sử dụng dịch vụ thông qua thông báo tại
              trang chủ của dịch vụ.
            </p>
            <h4>4. Trách nhiệm Chuang Cool</h4>
            <p>
              - Chuang Cool có trách nhiệm bảo mật những thông tin mà khách hàng
              cung cấp ngoại trừ các trường hợp được nêu trong bản thỏa thuận
              hoặc khi được sự cho phép của quý khách. Chuang Cool sẽ không cung
              cấp bất kỳ thông tin cá nhân nào của quý khách cho bên thứ ba mà
              không có sự đồng ý của quý khách. Chuang Cool có quyền cung cấp
              thông tin về quý khách cho các cơ quan thực thi pháp luật để điều
              tra trong trường hợp quý khách hoặc tài khoản của quý khách vi
              phạm pháp luật, hoặc giải quyết những vấn đề có liên quan đến pháp
              luật. Nếu quý khách yêu cầu bất kỳ sự trợ giúp kỹ thuật nào, quý
              khách đồng ý với truy nhập từ xa của chúng tôi và kiểm tra lại máy
              vi tính quý khách về việc tải phần mềm phục vụ cho mục đích trợ
              giúp và xử lý lỗi.
            </p>
            <p>
              - Chuang Cool có trách nhiệm hỗ trợ khách hàng sử dụng tài khoản
              trong quá trình chơi game.
            </p>
            <p>
              - Chuang Cool có trách nhiệm tiếp nhận và giải quyết các khiếu nại
              có liên quan tới tài khoản và sử dụng dịch vụ game trực tuyến theo
              thời gian quy định tiếp nhận xử lý sự cố quy định riêng cho từng
              loại lỗi.
            </p>
            <h3>IV. THỎA THUẬN DỊCH VỤ</h3>
            <h4>1. Sự đồng thuận của bạn</h4>
            <p>
              Bằng việc sử dụng tài khoản Chuang Cool ID đăng nhập chơi game
              trực tuyến của Chuang Cool bạn đã chứng tỏ rằng mình chấp nhận:
            </p>
            <p>- Các nội dung trong TTDV Chuang Cool.</p>
            <p>
              - Nếu bạn không đồng ý với TTDV Chuang Cool của chúng tôi thì xin
              vui lòng ngừng việc sử dụng dịch vụ game trực tuyến.
            </p>
            <h4>2. Các đường link và dịch vụ Chuang Cool</h4>
            <p>
              Chuang Cool không chịu bất kỳ trách nhiệm gì về các nghĩa vụ pháp
              lý của bạn ( nếu có ) phát sinh từ việc bạn sử dụng các dịch vụ,
              trang web của bên thứ ba không phải web và dịch vụ game online do
              Chuang Cool cung cấp. Do vậy, chúng tôi khuyến cáo bạn hãy thận
              trọng khi bạn rời các dịch vụ, trang web của Chuang Cool và hãy
              đọc kỹ các quy định chung và quy định riêng của mỗi dịch vụ, trang
              web mà bạn ghé thăm.
            </p>
            <h4>3. Thỏa thuận về xử lý lỗi và sự cố về giao dịch</h4>
            <p>3.1. Phát hiện lỗi hoặc giao dịch không hợp lệ/ chính xác:</p>
            <p>
              - Khi Quý Khách hàng có giao dịch mua nguyên bảo hoặc mua tiền tệ
              trong game, sau mỗi giao dịch được thực hiện chúng tôi đều cộng
              dịch vụ ngay vào tài khoản Chuang Cool ID , vào nhân vật ingame và
              đều có thông báo về mail thông báo giao dịch thành công. Nếu Quý
              Khách hàng có lý do để cho rằng tài khoản/nhân vật  của Quý Khách
              hàng đang có vấn đề, hãy thông báo ngay cho Chuang Cool biết khi:
            </p>
            <p>Có thông báo giao dịch không chính xác.</p>
            <p>
              Có giao dịch không hợp lệ được gửi tới tài khoản của Quý Khách
              hàng.
            </p>
            <p>Có sự truy cập không hợp lệ vào tài khoản của Quý Khách hàng.</p>
            <p>
              3.2. Thông báo lỗi hoặc giao dịch không hợp lệ/ chính xác tới
              Chuang Cool
            </p>
            <p>
              - Sau khi thực hiện giao dịch thành công, Trong vòng 24h kể từ khi
              thanh toán thành công mà tài khoản/ nhân vật Game của bạn chưa
              nhận được dịch vụ thì vui lòng liên hệ với Chuang Cool bằng cách
              gửi Khiếu nại, báo lỗi, cần trợ giúp tới địa chỉ email
              : goitaquanlaogia@gmail.com hoặc Inbox trang Facebook chính thức:
              Gọi Ta Quan Lão Gia để được hỗ trợ xử lý.
            </p>
            <p>
              Sau khoảng thời gian trên, nếu chúng tôi không nhận được bất kỳ
              email hay inbox Facebook thắc mắc nào từ phía bạn thì coi như mọi
              nghĩa vụ của chúng tôi đã được hoàn thành.
            </p>
            <p>
              Quý Khách hàng phải gửi thông báo tới Chuang Cool trong thời hạn
              là 24 giờ làm việc kể từ khi Quý Khách hàng phát hiện ra lỗi hoặc
              sự cố. Chuang Cool sẽ không phải chịu bất kỳ trách nhiệm nào nếu
              khách hàng thông báo lỗi/sự cố quá thời hạn trên.
            </p>
            <p>3.3. Xem xét và xử lý thông báo của Quý Khách hàng:</p>
            <p>
              - Chuang Cool sẽ gửi thông báo kết quả điều tra về các trường hợp
              này trong vòng mười (10) ngày làm việc sau khi Chuang Cool nhận
              được thông báo của Quý Khách hàng (hoặc 20 ngày làm việc cho các
              Giao dịch đã hoàn thành). Nếu lỗi thuộc về phía Chuang Cool
            </p>
            <p>
              – Chúng tôi sẽ khắc phục ngay lập tức. Tuy nhiên khi cần thêm thời
              gian, Chuang Cool có thể sẽ dùng đến chín mươi (90) ngày để xem
              xét các khiếu nại cho các giao dịch đã hoàn thành hoặc bên ngoài
              Việt Nam.
            </p>
            <p>
              Chuang Cool sẽ chịu trách nhiệm cho các thiệt hại mà Quý Khách
              hàng phải chịu, trừ các trường hợp sau:
            </p>
            <p>Lỗi không phải do Chuang Cool gây ra;</p>
            <p>
              Khách hàng thông báo lỗi/sự cố vượt quá thời hạn quy định của Thỏa
              thuận này.
            </p>
            <p>
              Hệ thống của Chuang Cool không hoạt động tốt và Quý Khách hàng “đã
              biết” về việc này khi tiến hành giao dịch;
            </p>
            <p>
              Do đường truyền internet: Do lỗi đường truyền, lỗi kết nối từ, đứt
              cáp quang, đứt mạng…. do những lỗi đường truyền mà Chuang
              Cool không thể can thiệp được.
            </p>
            <p>
              Do các chính sách tạm giữ tiền từ phía Ngân hàng, chính sách kiểm
              duyệt giao dịch giao dịch từ phía hệ thống ngân hàng, tài khoản
              của Quý Khách hàng hoặc do đường truyền kết nối giữa Chuang Cool
              và các ngân hàng mà Quý Khách hàng chọn thanh toán;
            </p>
            <p>
              Chuang Cool có quyền được miễn trừ mọi trách nhiệm trong việc thực
              hiện thỏa thuận này trong trường hợp bị cản trở hoặc ngăn cản bởi
              hỏa hoạn, lũ lụt, động đất hoặc thảm họa thiên nhên khác. Hoặc do
              việc đình công, bãi công, chiến tranh, bạo động, hoặc bất kỳ tình
              huống tương tự nào vượt quá sự kiểm soát của Chuang Cool. Nếu
              Chuang Cool không thể thực hiện nghĩa vụ với Quý Khách hàng do bất
              kỳ nguyên nhân nào nêu trên hoặc sự kiện nào tương tự. Chuang Cool
              sẽ cố gắng gửi thông báo cho Quý Khách hàng về sự kiện đã xảy ra
              và hành động của chúng tôi.
            </p>
            <p>3.4. Chính sách hoàn, hủy:</p>
            <p>
              - Các khiếu nại liên quan tới vấn đề tài khoản như tranh chấp tài
              khoản (do mua bán, chuyển nhượng, chơi chung….) hoặc các giao dịch
              đang có khiếu nại, tranh chấp (giả mạo thẻ, charge back) thì
              Chuang Cool có quyền khóa tài khoản cho đến khi khiếu nại được
              giải quyết xong.
            </p>
            <p>
              - Các khiếu nại liên quan tới giao dịch nạp vào game (qua thẻ
              cào/thẻ Ngân hàng), Chuang Cool chỉ tiếp nhận xử lý các giao dịch
              trong tháng xảy ra giao dịch và phải gửi khiếu nại của giao dịch
              đó trước ngày cuối cùng của tháng. Các khiếu nại giao dịch nạp
              vượt quá thời gian quy định Chuang Cool sẽ không tiếp nhận xử lý
            </p>
            <p>
              - Chuang Cool không chịu trách nhiệm bồi thường bất kỳ thiệt hại
              nào trong quá trình xử lý khiệu nại về giao dịch.
            </p>
            <h4>4. Thỏa thuận về xử lý lỗi và sự cố về tài khoản/lỗi game</h4>
            <p> 4.1. Phát hiện lỗi về tài khoản</p>
            <p>
              Nếu Quý Khách hàng có lý do để cho rằng tài khoản/nhân vật của Quý
              Khách hàng đang có vấn đề, hãy thông báo ngay cho Chuang Cool biết
              khi:
            </p>
            <p>Có thông báo lỗi đăng nhập (login)</p>
            <p>Có thông bảo tài khoản đang bị đăng nhập ở thiết bị khác …</p>
            <p>Có sự truy cập không hợp lệ vào tài khoản của Quý Khách hàng.</p>
            <p>Có phát sinh lỗi trong quá trình chơi game…</p>
            <p>4.2 Thông báo lỗi tới Chuang Cool</p>
            <p>
              - Nếu Quý Khách hàng có lý do để cho rằng tài khoản/nhân vật của
              Quý Khách hàng đang có vấn đề, hãy thông báo ngay cho Chuang Cool
              qua email :  goitaquanlaogia@gmail.com hoặc Inbox trang Facebook
              chính thức: Gọi Ta Quan Lão Gia để được hỗ trợ xử lý
            </p>
            <p>
              - Quý Khách hàng phải gửi thông báo tới Chuang Cool trong thời hạn
              là 1h giờ làm việc kể từ khi Quý Khách hàng phát hiện ra lỗi hoặc
              sự cố. Chuang Cool sẽ không phải chịu bất kỳ trách nhiệm nào nếu
              khách hàng thông báo lỗi/sự cố quá thời hạn trên.
            </p>
            <p>4.3. Xem xét và xử lý thông báo của Quý Khách hàng:</p>
            <p>
              - Chuang Cool sẽ gửi thông báo kết quả điều tra về các trường hợp
              này trong vòng 3 ngày (72 giờ) làm việc sau khi Chuang Cool nhận
              được thông báo của Quý Khách hàng (hoặc 7 ngày làm việc tùy theo
              tình trạng lỗi). Nếu lỗi thuộc về phía Chuang Cool – Chúng tôi sẽ
              khắc phục ngay lập tức. Tuy nhiên khi cần thêm thời gian, Chuang
              Cool có thể sẽ dùng đến chín mươi (90) ngày để xem xét tùy vào
              loại lỗi cụ thể.
            </p>
            <p>
              Tôi đã đọc và hiểu TTDV Chuang Cool và đồng ý bị ràng buộc bởi
              toàn bộ các điều khoản.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "../components/navigation";
import api from "@/api";
import getNowTime from "@/methods/getNowTime";

export default {
  name: "home",
  components: {
    Navigation,
  },
  data() {
    return {
      isSky: false,
      isReelActive: false,
      active: null, // 角色移入变亮
      isActivePro: false,
      isActiveUser: false,
      newsList: [],
      pageNum: 1,
      pageSize: 5,
      totalPages: null,
      cartoonContent: "",
    };
  },
  computed: {
    roles() {
      //   let arr = ['menke','meiren','wuhu','wuyi','suitang','jiancheng','nvjie']
      let arr = [
        {
          key: "0",
          name: "menke",
        },
        {
          key: "3",
          name: "meiren",
        },
        {
          key: "5",
          name: "wuhu",
        },
        {
          key: "1",
          name: "wuyi",
        },
        {
          key: "6",
          name: "suitang",
        },
        {
          key: "2",
          name: "jiancheng",
        },
        {
          key: "4",
          name: "nvjie",
        },
      ];
      return this.imgPath(arr);
    },
  },
  mounted() {
    // 视频加载
    let $this = this;
    let vid = document.getElementById("video");
    vid.play();
    vid.onended = function () {
      // 云飘
      $this.isSky = true;
    };
    //卷轴动画
    this.isReelActive = true;
    // 资讯列表加载
    this.getNewsList();
    let fbPage = document.querySelector(".fb-page");

    // fbPage.setAttribute('data-width','1000')
    FB.XFBML.parse();
  },
  methods: {
    getNowTime: getNowTime,
    imgPath(arr) {
      let rolesArr = [];
      arr.map((e, i) => {
        let obj = {};
        obj["img"] = `${this._myPath}/${e.name}.png`;
        obj["key"] = e.key;
        // obj['img_hover']=`${this._myPath}/${e}_hover.png`
        // obj['img_txt']=`${this._myPath}/${e}_txt.png`
        rolesArr.push(obj);
      });
      return rolesArr;
    },
    getNewsList(pageNum, pageSize) {
      let pageN = pageNum || this.pageNum;
      let pageS = pageSize || this.pageSize;
      api.getNewsList().then((res) => {
        let resData = res.data;
        if (resData.code === 200) {
          this.pageNum = resData.currentPage;
          this.newsList = resData.data;
          this.totalPages = resData.totalPages;
        }
      });
    },
    goDetail(item) {
      let tagId = null;
      if (item.tag == "Thông báo") {
        tagId = 1;
      } else if (item.tag == "Hoạt động") {
        tagId = 2;
      } else {
        tagId = 3;
      }
      this.$router.push(`/newsDetail?tagId=${tagId}&newsId=${item.n_id}`);
    },
    goPage(param) {
      if (param === "prev") {
        if (this.pageNum - 1 <= 0) return false;
        this.getNewsList(this.pageNum - 1);
      } else {
        if (this.pageNum + 1 > this.totalPages) return false;
        this.getNewsList(this.pageNum + 1);
      }
    },
    goRoles(index) {
      this.$router.push(`/roles?roleId=${index}`);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.home {
  width: 100%;
  height: 100%;
}

.section {
  position: relative;
  width: 100%;
}
.bg {
  width: 100%;
  display: block;
}
.section1 {
  position: relative;
  font-size: 0;

  .video {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .sky {
    width: 100%;
    top: 0;
    opacity: 0.7;
    height: 100%;
    background: transparent;
    position: absolute;
    overflow: hidden;
    animation: sky_background 50s ease-out infinite;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    .clouds_one {
      background: url("../assets/images/cloud_one.png");
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 300%;
      -webkit-animation: cloud_one 50s linear infinite;
      -moz-animation: cloud_one 50s linear infinite;
      -o-animation: cloud_one 50s linear infinite;
      animation: cloud_one 50s linear infinite;
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .clouds_two {
      background: url("../assets/images/cloud_two.png");
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 300%;
      -webkit-animation: cloud_two 75s linear infinite;
      -moz-animation: cloud_two 75s linear infinite;
      -o-animation: cloud_two 75s linear infinite;
      animation: cloud_two 75s linear infinite;
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .clouds_three {
      background: url("../assets/images/cloud_three.png");
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 300%;
      -webkit-animation: cloud_three 100s linear infinite;
      -moz-animation: cloud_three 100s linear infinite;
      -o-animation: cloud_three 100s linear infinite;
      animation: cloud_three 100s linear infinite;
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    @keyframes cloud_one {
      0% {
        left: 0;
      }
      100% {
        left: -200%;
      }
    }
    @keyframes cloud_two {
      0% {
        left: 0;
      }
      100% {
        left: -200%;
      }
    }
    @keyframes cloud_three {
      0% {
        left: 0;
      }
      100% {
        left: -200%;
      }
    }
  }
}
.section2 {
  .bg {
    width: 100%;
  }
  .mb_bg {
    display: none;
  }
}
.frist {
  display: flex;
  position: absolute;
  width: 100%;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  .left {
    flex: 1;
    margin: 0 150px;
  }
  .news,
  .fb,
  .introduce {
    margin: 0px 0px;
    padding-top: 100px;
    .title {
      width: 600px;
    }
    .reel {
      width: 2000px;
      height: 1300px;
      background: url("../assets/images/reel_open.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      position: relative;
    }
    .fb_reel {
      height: 1300px;
      background: url("../assets/images/reel_open.png") no-repeat;
      background-size: 100% 100%;
    }
    .content {
      position: absolute;
      width: 78%;
      height: 930px;
      top: 160px;
      left: 50%;
      transform: translateX(-50%);
      overflow: auto;
      text-align: left;
      p {
        text-indent: 50px;
        font-size: 40px;
      }
      .title {
        text-indent: 0px;
        width: 100%;
        text-align: center;
        font-weight: 700;
        padding: 20px;
      }
    }
  }

  .introduce {
    .content {
      position: absolute;
      width: 78%;
      height: 930px;
      top: 160px;
      left: 50%;
      transform: translateX(-50%);
      overflow: auto;
      text-align: left;
      p {
        text-indent: 50px;
        font-size: 50px;
      }
    }
  }
  .list {
    position: absolute;
    width: 100%;
    height: 950px;
    top: 170px;
    left: 50%;
    overflow: hidden;
    transform: translateX(-50%);
    // .fb-page[data-width='500']{
    //     width: 1000px !important;
    // }
    // .fb_iframe_widget{
    //     width: 100%;
    //     height: 100%;
    //     span{
    //         display: block;
    //         width: 500px !important;
    //     }
    // }
    .fb_iframe_widget iframe {
      width: 1000px !important;
      ._2lqh {
        height: 0;
        display: none !important;
      }
      ._2p3a {
        width: 400px !important;
      }
    }
  }
  .news {
    .news_tags {
      position: absolute;
      width: 80%;
      height: 910px;
      top: 190px;
      left: 50%;
      overflow: auto;
      transform: translateX(-50%);
      li {
        font-size: 50px;
        color: #000;
        font-weight: 500;
        padding-bottom: 25px;
        margin-bottom: 40px;
        cursor: pointer;
        .tag {
          display: inline-block;
          width: 280px;
          padding: 10px 0px;
          background-color: #993620;
          border-radius: 10px;
          color: #fff;
        }
        .title {
          display: inline-block;
          width: 750px;
          margin: 0 50px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          vertical-align: bottom;
        }
      }
    }
    .news_page {
      position: absolute;
      width: 100%;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      display: none;
      .prev_btn {
        width: 65px;
        position: absolute;
        top: 0;
        left: -100px;
        cursor: pointer;
      }
      .back_btn {
        width: 65px;
        position: absolute;
        top: 0;
        right: -100px;
        cursor: pointer;
      }
    }
  }
}
.second {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  .roles {
    // margin: 0 auto;
    position: relative;
    width: 100%;
    height: 1750px;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 150px;
    z-index: 9;
    > div {
      display: inline-block;
      cursor: pointer;
      .role_img {
        width: 400px;
      }
      // .role_txt{
      //     width:500px;
      //     position: absolute;
      //     cursor: pointer;
      // }
      // &:nth-child(1){
      //     left: 0px;
      //     top: 480px;
      //     .role_img{
      //         width: 1150px;
      //     }
      //     .role_txt{
      //         left: 120px;
      //         bottom: 420px;
      //     }
      // }
      //  &:nth-child(3){
      //      top:550px;
      //      right: 520px;
      //      z-index: 2;
      //     .role_img{
      //         width: 1200px;
      //     }
      //     .role_txt{
      //         left: 200px;
      //          bottom: 400px;
      //     }
      // }
      //  &:nth-child(2){
      //      top: 550px;
      //      left: 1200px;
      //     .role_img{
      //         width: 1200px;
      //      }
      //     .role_txt{
      //         left: 300px;
      //          bottom: 350px;
      //     }
      // }
      // &:nth-child(4){
      //      top: 420px;
      //      left: 550px;
      //      z-index: 2;
      //     .role_img{
      //         width: 1100px;
      //      }
      //     .role_txt{
      //         left: 300px;
      //          bottom: 480px;
      //     }
      // }
      //  &:nth-child(5){
      //     top: 450px;
      //     right: 0px;
      //     z-index: 2;
      //     .role_img{
      //         width: 1250px;
      //     }
      //     .role_txt{
      //         left: 620px;
      //          bottom: 400px;
      //     }
      // }
    }
    .role_cloud {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -280px;
      z-index: 2;
    }
  }
}
.android:after,
.apple:after,
.apk:after {
  content: "";
  background: #ddd;
  display: block;
  position: absolute;
  width: 10%;
  height: 100%;
  border-radius: 100px;
  top: -150px;
  left: 0px;
  transform: scale(1, 1) rotate(45deg);
  opacity: 0;
  animation: mymove 1s ease-in-out infinite;
}
@keyframes mymove {
  0% {
    opacity: 0.6;
    transform: scale(1, 1) rotate(45deg);
  }
  100% {
    opacity: 0;
    transform: scale(20, 20) rotate(45deg);
  }
}
.frist .download {
  position: relative;
  bottom: 50px;
  right: 50px;
  a {
    display: inline-block;
    width: 500px;
    height: 150px;
    margin: 0px 100px;
    text-align: right;
    position: relative;
    overflow: hidden;
    border-radius: 100px;
    img {
      display: block;
      width: 500px;
      cursor: pointer;
    }
  }
}
.three,
.download {
  position: absolute;
  bottom: 35px;
  right: 150px;
  z-index: 99;
  // 屏蔽后
  // bottom: 80px;
  a {
    display: block;
    width: 400px;
    height: 110px;
    margin: 35px;
    text-align: right;
    position: relative;
    overflow: hidden;
    border-radius: 100px;
  }
  img {
    display: block;
    width: 300px;
    cursor: pointer;
  }
}

.footer {
  position: relative;
  .footer_bg {
    width: 100%;
    display: block;
  }
  .mb_footer_bg {
    display: none;
  }
  .pro_btn {
    position: absolute;
    display: inline-block;
    width: 210px;
    height: 75px;
    top: 130px;
    left: 810px;
    cursor: pointer;
  }
  .user_btn {
    position: absolute;
    display: inline-block;
    width: 600px;
    height: 75px;
    top: 70px;
    left: 100px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .logo {
    position: absolute;
    width: 350px;
    top: 20px;
    right: 320px;
  }
  .logo_ {
    position: absolute;
    width: 300px;
    top: 80px;
    right: 350px;
  }
}
.pro-pop {
  position: fixed;
  z-index: 99;
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  .img {
    width: 80%;
    -webkit-transform: translateY(calc(50vh - 50%));
    transform: translateY(calc(50vh - 50%));
    position: relative;
    top: 0;
    left: 10%;
    .border {
      width: 100%;
    }
    .mb_protocol_box {
      display: none;
    }
  }
  .title {
    position: absolute;
    top: 4%;
    left: 10%;
    width: 80%;
    font-size: 80px;
    line-height: 1.2;
    color: #fff;
    font-weight: 700;
    text-align: center;
  }
  .close {
    width: 130px;
    position: absolute;
    top: 5%;
    right: 5%;
    cursor: pointer;
  }
  .text {
    position: absolute;
    top: 20%;
    left: 12.5%;
    width: 76%;
    height: 75%;
    text-align-last: left;
    text-align: justify;
    .protocol,
    .user {
      line-height: 2;
      width: 100%;
      height: 98%;
      overflow: auto;
      h3 {
        text-align: center;
        text-align-last: center;
        font-size: 65px;
        font-weight: 700;
      }
      h4 {
        font-size: 50px;
        font-weight: 700;
      }
      p {
        font-size: 40px;
      }
    }
  }
}
// 移动端
@media screen and (max-width: 750px) {
  .section2 {
    .bg {
      display: none;
    }
    .mb_bg {
      width: 100%;
      display: block;
    }
  }
  .frist {
    top: 0;
    .left {
      padding-top: 100px;
    }
    .news,
    .fb,
    .introduce {
      padding-top: 0px;
      .reel {
        width: 3200px;
        height: 1600px;
        margin-right: 20px;
      }
      .title {
        width: 850px;
      }
    }
    .news {
      margin-top: 0px;
      padding-top: 100px;
      .news_tags {
        height: 1200px;
        top: 220px;
        li {
          font-size: 100px;
          font-weight: 500;
          padding-bottom: 25px;
          margin-bottom: 40px;
          .tag {
            width: 800px;
            padding: 20px 0px;
          }
          .title {
            width: 900px;
            margin: 0 50px;
          }
        }
      }
    }
    .fb {
      padding-top: 50px;
      .list {
        position: absolute;
        width: 90%;
        height: 1250px;
        .fb_iframe_widget iframe {
          width: 800px !important;
          ._2lqh {
            display: none !important;
          }
          ._2p3a {
            width: 400px !important;
          }
        }
      }
    }
    .introduce {
      padding-top: 0px;
      .content {
        height: 1200px;
        top: 180px;
        left: 50%;
        p {
          text-indent: 50px;
          font-size: 100px;
        }
      }
    }
  }
  .frist .download {
    position: relative;
    a {
      width: 800px;
      height: 200px;
      img {
        width: 800px;
      }
    }
  }
  .second .roles {
    padding-top: 100px;
    > div {
      padding-top: 500px;
      .role_img {
        width: 400px;
      }
      // .role_txt{
      //     width: 500px;
      //     position: absolute;
      // }
      //  &:nth-child(4){
      //      top: 500px;
      //      left:600px;
      //     .role_img{
      //         width: 1000px;
      //     }
      //     .role_txt{
      //         top: 880px;
      //         left: 200px;
      //     }
      // }
      // &:nth-child(3){
      //     top: 550px;
      //     right: 580px;
      //     .role_img{
      //         width: 1100px;
      //     }
      //     .role_txt{
      //         bottom: 300px;
      //         left: 300px;
      //     }
      // }
      //  &:nth-child(2){
      //      top: 520px;
      //      left: 1200px;
      //     .role_img{
      //         width: 1200px;
      //     }
      //     .role_txt{
      //         position: absolute;
      //         bottom: 320px;
      //     }
      // }
      //  &:nth-child(1){
      //     top: 500px;
      //     .role_img{
      //         width: 1400px;
      //     }
      //     .role_txt{
      //         position: absolute;
      //         left: 100px;
      //         top: 880px;
      //     }
      // }
      //  &:nth-child(5){
      //     top: 500px;
      //     .role_img{
      //         width: 1200px;
      //     }
      //     .role_txt{
      //         position: absolute;
      //         left: 600px;
      //     }
      // }
    }
    .role_cloud {
      display: none;
    }
  }
  .section3 {
    img.bg {
      display: none;
    }
  }
  .three {
    position: relative;
    height: 650px;
    right: 0px;
    bottom: 0;
    background: url("../assets/images/mb_bg4.jpg") no-repeat;
    background-size: 100% 100%;
    padding: 80px;
    text-align: right;
    a {
      position: absolute;
      width: 500px;
      height: 150px;
      top: 0px;
      right: 0;
      overflow: hidden;
      border-radius: 300px;
      background: transparent;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    // 屏蔽前
    .android {
      top: 220px;
      right: 50px;
    }
    // 屏蔽后
    // .android{
    //     top: 200px;
    //     right: -40px;
    // }
    .apple {
      top: 10px;
      right: 50px;
    }
    .apk {
      top: 440px;
      right: 50px;
    }
    .android:after,
    .apple:after,
    .apk:after {
      border-radius: 200px;
      top: -200px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    img {
      display: inline-block;
      width: 500px;
      margin: 0;
    }
  }
  .pro-pop {
    .img {
      .protocol_box {
        display: none;
      }
      .mb_protocol_box {
        display: block;
      }
    }
    .title {
      left: 8%;
      top: 15%;
      font-size: 150px;
    }
    .close {
      top: 13%;
      right: 5%;
      width: 250px;
    }
    .text {
      top: 26%;
      width: 76%;
      height: 65%;
      .protocol,
      .user {
        height: 100%;
        h3 {
          font-size: 120px;
        }
        h4 {
          font-size: 100px;
        }
        p {
          font-size: 100px;
        }
      }
    }
  }
  .footer {
    .footer_bg {
      display: none;
    }
    .mb_footer_bg {
      width: 100%;
      display: block;
    }
    // .logo{
    //     display: none;
    // }
    .user_btn {
      width: 720px;
      height: 150px;
      top: 100px;
      left: 600px;
    }
  }
}
</style>

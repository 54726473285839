<template>
  <div class="roles">
        <Navigation></Navigation> 
        <router-link to="/">
            <img src="../assets/images/back_btn.png" alt="" class="back_btn">
        </router-link>
        <div class="bg"></div>
        <div class="mb_news_bg"></div>
        <div class="roles_content">
            <ul class="roles_article">
                <li v-for="(item,index) in articles" :key="index" @click="toggleTab(index)">
                    <img :src="item" alt="">
                </li>
            </ul>
            <div class="roles_lists">
                <ul v-for="(item,index) in tabs" :key="index" :class="item.styleClass" v-show="activeRolesIndex==index">
                    <li v-for="(role,index) in item.roles" :key="index" @click="getRolesDetail(item.styleClass,index,item.roles)">
                        <img :src="role" alt="" v-if="!item.names">
                        <img v-if="item.names" :src="item.names[index]" alt="" class="role_list_name" 
                        @click="getRolesDetail(item.styleClass,index,item.roles,true)">
                    </li>
                    <!-- <img src="../assets/images/role_cloud.png" alt="" v-if="activeRolesIndex!=0 && activeRolesIndex!=4" class="role_cloud"> -->
                </ul>
                <!-- <img src="../assets/images/black1.png" alt="" v-if="activeRolesIndex==1" class="wuhu_black1"> -->
                <!-- <img src="../assets/images/black2.png" alt="" v-if="activeRolesIndex==1" class="wuhu_black2"> -->
            </div>
        </div>
        <div class="roles_mask" v-show="isRoleDetail">
            <div class="roles_introduce">
                <img src="../assets/images/close_role.png" alt="" class="close_role" @click="isRoleDetail=false">
                <div class="introduce_left">
                    <div class="chongai-pihu">
                        <div class="yuan" v-bind:class="{yuan_selected:isActiveRole===1}" @click="toggleRoleAttr(1)"></div>
                        <div v-bind:class="{chongai_selected:isActiveRole===2}" v-show="isChongai" class="chongai" @click="toggleRoleAttr(2)"></div>
                        <div v-bind:class="{pifu_selected:isActiveRole===3}" v-show="isPifu"  class="pifu" @click="toggleRoleAttr(3)"></div>
                        <div v-bind:class="{pifu_chongai_selected:isActiveRole===4}" v-show="isPifuChongai"  class="pifu_chongai" @click="toggleRoleAttr(4)"></div>
                    </div>
                    <img :src="roleDetailImg" alt="" v-bind:class="{'meiren_img':activeRolesIndex===3}">
                </div>
                <div class="introduce_right">
                    <div class="roleName">{{currentRole.name}}</div>
                    <div class="roleTxt">
                        <!-- <div class="roleRead">{{currentRole.read}}</div> -->
                        <div class="roleIntroduce">
                            {{currentRole.introduce}}
                        </div>
                    </div>
                </div>
                <img src="../assets/images/role_introduce_front.png" alt="" class="front_btn" 
                v-show="currentIndex!==0" @click="rolePage('front')">
                <img src="../assets/images/role_introduce_back.png" alt="" class="back_btn"
                 v-show="currentIndex!==(currentList.length-1)" @click="rolePage('back')">
            </div>
        </div>
  </div>
</template>

<script>
import Navigation from '../components/navigation'
import api from '@/api'
import {roleDetails} from '../role.js'
import getQueryString from '@/methods/getQueryString'
export default {
  name: 'news',
    components:{
      Navigation
  },
  data(){
      return{
        isMeiren:false,
        isActiveNewsTitle:0,
        pageNum:1,
        activeRolesIndex:0,
        isRoleDetail:false,
        roleDetailImg:null,
        isChongai:false,
        isPifu:false,
        isPifuChongai:false,
        isActiveRole:1,
        roleDetails:roleDetails,
        currentRole:{},
        currentIndex:null,
        currentList:[],
        currentListName:'',
        roleId:0
      }
  },
  computed: {
    // 侧边栏
    articles(){
        let articlesArr=['article_menke','article_wuyi','article_jiancheng','article_meiren','article_nvjie','article_wuhu','article_suitang']
        return this.imgPath(articlesArr)
    },
    //角色
    tabs () {
        return [
            {
                styleClass:'menke_list',
                roles: this.publicPath(47, 'menke')
            },
            {
                styleClass:'wuyi_list',
                roles: new Array(5),
                names:this.namePath(5,"wuyi")
            },
            {
                styleClass:'jiancheng_list',
                roles: new Array(4),
                names:this.namePath(4,"jiancheng")
            }, 
            {
                styleClass:'meiren_list',
                roles: this.publicPath(36, 'meiren')
            },
            {
                styleClass:'nvjie_list',
                roles:new Array(5),
                names:this.namePath(5,"nvjie")
            }, 
            {
                styleClass:'wuhu_list',
                roles: new Array(5),
                names:this.namePath(5,"wuhu")
            },
            {
                styleClass:'suitang_list',
                roles: new Array(4),
                names:this.namePath(4,"suitang")
            },
        ]
    },
  },
  mounted(){
    this.roleId = getQueryString("roleId");
    this.activeRolesIndex = this.roleId || 0 ;
    this.getView();
  },
  methods:{
        toggleTab(index){
          this.activeRolesIndex = index ;
          this.roleId = index;
          this.getView();
        },
        // 浏览
        getView(){
            api.view(JSON.stringify({
                type:'role',
                r_id:this.roleId+1
            }))
        },
        // 切换角色详情属性
        toggleRoleAttr(param){
          this.isActiveRole = param
          if(param === 1){
              this.roleDetailImg = this.currentRole.img
          }else if(param === 2){
              this.roleDetailImg = this.currentRole.img_chongai
          }else if(param === 3){
              this.roleDetailImg = this.currentRole.img_pifu
          }else{
              this.roleDetailImg = this.currentRole.img_pifu_chongai
          }

      },
      // 角色详情
      getRolesDetail(listName,index,lists,rolename){
          let rolesName =listName.split('_')[0]
          if(rolesName !== 'meiren' && rolesName !== 'menke' && !rolename){
              return false;
          }
          this.isRoleDetail = true
          this.isActiveRole = 1
          this.currentListName = listName
          this.currentIndex = index
          this.currentList =lists
          this.currentRole = this.roleDetails[rolesName][index]
          this.roleDetailImg = this.currentRole.img
          this.isChongai = this.currentRole.img_chongai?true:false;
          this.isPifu = this.currentRole.img_pifu?true:false;
          this.isPifuChongai = this.currentRole.img_pifu_chongai?true:false;
            // if(this.currentRole.img_pifu){
            //     this.isPifu = true
            //     this.isPifuChongai = rolesName === "meiren"?true:false
            // }else{
            //     this.isPifu = false
            //     this.isPifuChongai = false
            // }
      },
      // 角色分页
      rolePage(param){
          let currentIndex=param === "front"?this.currentIndex-1:this.currentIndex+1
          this.getRolesDetail(this.currentListName,currentIndex,this.currentList,true)
      },
      // 获取角色图片路径
      publicPath(length, fileName) {
        return Array.from({length: length}).map((e, index) =>`${this._myPath}/${fileName}/${fileName}_${index + 1 < 10 ? `0${index+1}` : index + 1}.png`)
      },
      // 获取角色名称
      namePath(length,fileName){
        return Array.from({length: length}).map((e, index) =>`${this._myPath}/${fileName}/${fileName}_name_${index + 1 < 10 ? `0${index+1}` : index + 1}.png`)
      },
      // 获取侧边切换图片
      imgPath(arr, dir){
          return dir?arr.map( e => `${this._myPath}/${dir}/${e}.png`)
          :arr.map( e => `${this._myPath}/${e}.png`)
      }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    .bg{
        background: url('../assets/images/bg.jpg') no-repeat;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
     .mb_news_bg{
        background: url('../assets/images/mb_news_bg.jpg') no-repeat;
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        display: none;
    }
    .roles{
        width: 100%;
        height: 100%;
        text-align: left;
    }
    .back_btn{
        width:232px;
        margin-top: 150px;
        margin-left: 80px;
        cursor: pointer;
    }
    .roles_content{
        width: 100%;
        position: relative;
        .roles_article{
            position: absolute;
            left: 160px;
            top: 0;
            z-index: 9;
            li{
                cursor: pointer;
                margin-bottom: -20px;
                img{
                    height: 220px;
                }
            }
        }
        .roles_lists{
            position: absolute;
            top: 0;
            left: 1150px;
            z-index: 1;
            .name(@width:300px,@imgTop: 300px, @imgLeft:400px) {
                    img.role_list_name{
                    width:@width;
                    top: @imgTop;
                    left: @imgLeft;
                }
            }
            
            .jiancheng_list{
                width: 3200px;
                height: 1700px;
                margin-left: -900px;
                background: url('../assets/images/jiancheng_bg.png') no-repeat;
                background-size: 100% 100%;
                li{
                    position: absolute;
                    img{
                        width: 100%;
                    }
                    img.role_list_name{
                        width: 300px;
                        position: absolute;
                        z-index: 2;
                        cursor: pointer;
                    }
                    &:nth-child(1){
                        .name(500px,400px,1000px)
                    }
                    &:nth-child(2){
                        .name(500px,400px,2000px)
                    }
                    &:nth-child(3){
                        .name(500px,1100px,1000px)
                    }
                    &:nth-child(4){
                        .name(500px,1100px,1800px)
                    }
                }
            }
            .nvjie_list{
                width: 3200px;
                height: 1700px;
                margin-left: -1000px;
                background: url('../assets/images/nvjie_bg.png') no-repeat;
                background-size: 100% 100%;
                 .role_cloud{
                    width: 3000px;
                    position:absolute;
                    top: 1380px;
                    left: -900px;
                    z-index: 2;  
                }
                li{
                    position: absolute;
                    img{
                        width: 100%;
                    }
                    img.role_list_name{
                        width: 500px;
                        position: absolute;
                        z-index: 2;
                        cursor: pointer;
                    }
                    &:nth-child(1){
                        .name(500px,450px,1300px)
                    }
                    &:nth-child(2){
                        .name(500px,450px,1850px)
                    }
                    &:nth-child(3){
                        .name(500px,1000px,1000px)
                    }
                    &:nth-child(4){
                        .name(500px,1100px,1550px)
                    }
                     &:nth-child(5){
                        .name(500px,1100px,2100px)
                    }
                }
            }
            .wuyi_list{
                 width: 3100px;
                height: 1700px;
                margin-left: -850px;
                background: url('../assets/images/wuyi_bg.png') no-repeat;
                background-size: 100% 100%;
                .role_cloud{
                    width: 3000px;
                    position:absolute;
                    top: 1350px;
                    left: -900px;
                    z-index: 2;   
                }
                li{
                    position: absolute;
                    img{
                        width: 100%;
                    }
                    img.role_list_name{
                        width: 100px;
                        position: absolute;
                        z-index: 2;
                        cursor: pointer;
                    }
                    &:nth-child(1){
                        .name(500px,550px,800px)
                    }
                    &:nth-child(2){
                        .name(500px,500px,1400px)
                    }
                    &:nth-child(3){
                        .name(500px,400px,2000px)
                    }
                    &:nth-child(4){
                        .name(500px,1000px,1000px)
                    }
                     &:nth-child(5){
                        .name(500px,1000px,2000px)
                    }
                }
            }
            .wuhu_list{
                 width: 3100px;
                height: 1700px;
                margin-left: -850px;
                background: url('../assets/images/wuhu_bg.png') no-repeat;
                background-size: 100% 100%;
                .role_cloud{
                    width: 3000px;
                    position:absolute;
                    top: 1350px;
                    left: -900px;
                    z-index: 2;   
                }
                li{
                    position: absolute;
                    img{
                        width: 100%;
                    }
                    img.role_list_name{
                        width: 100px;
                        position: absolute;
                        z-index: 2;
                        cursor: pointer;
                    }
                    &:nth-child(1){
                        .name(500px,700px,800px)
                    }
                    &:nth-child(2){
                        .name(500px,500px,1400px)
                    }
                    &:nth-child(3){
                        .name(500px,600px,2200px)
                    }
                    &:nth-child(4){
                        .name(500px,1000px,1000px)
                    }
                     &:nth-child(5){
                        .name(500px,1000px,2000px)
                    }
                }
            }
            .suitang_list{
                width: 2800px;
                height: 1600px;
                margin-left: -820px;
                background: url('../assets/images/suitang_bg.png') no-repeat;
                background-size: 100% 100%;
                .role_cloud{
                    width: 3000px;
                    position:absolute;
                    top: 1350px;
                    left: -900px;
                    z-index: 2;   
                }
                li{
                    position: absolute;
                    img{
                        width: 100%;
                    }
                    img.role_list_name{
                        width: 100px;
                        position: absolute;
                        z-index: 2;
                        cursor: pointer;
                    }
                    &:nth-child(1){
                        .name(500px,800px,600px)
                    }
                    &:nth-child(2){
                        .name(500px,460px,1500px)
                    }
                     &:nth-child(3){
                        .name(500px,1000px,1200px)
                    }
                    &:nth-child(4){
                        .name(500px,700px,2100px)
                    }
                   
                }
            }
            .meiren_list,.menke_list{
                position: relative;
                left: -300px;
                li{
                    cursor: pointer;
                    float: left;
                    width: 300px;
                    margin: 10px;
                    img{
                       width: 100%;
                    }
                }
            }
        }
    }
    .roles_mask{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.8);
        z-index: 999;
        .roles_detail_bg{
            width: 80%;
            position:absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
       
    }
    .roles_introduce{
        width: 75%;
        height: 1500px;
        position: fixed;
        display: flex;
        display: flex;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: url('../assets/images/roles_detail_bg.png') no-repeat;
        background-size: 100% 100%;
        .close_role{
            position:absolute;
            top:0;
            right: 100px;
            width: 150px;
            cursor: pointer;
            z-index: 1;
        }
        .introduce_left{
            margin-left: 150px;
            flex: 1;
            padding: 200px;
            text-align: center;
            img{
                width: 1000px;
                position: absolute;
                bottom: 70px;
                left: 380px;
                margin-left:100px;
                z-index: -1;
            }
            .meiren_img{
                bottom: 105px;
                margin-left: 180px;
            }
            .chongai-pihu{
                position: absolute;
                top: 180px;
                left: 250px;
                >div{
                    width: 330px;
                    height: 120px;
                    margin-bottom: 20px;
                    cursor: pointer;
                }
                .yuan{
                    width: 410px;
                    background: url('../assets/images/yuan_normal.png') no-repeat;
                    background-size: 100% 100%;
                    margin-left: 20px;
                }
                .yuan_selected{
                    background: url('../assets/images/yuan_selected.png') no-repeat;
                    background-size: 100% 100%;
                }
                .chongai{
                    background: url('../assets/images/chongai_normal.png') no-repeat;
                    background-size: 100% 100%;
                    margin-left: 50px;
                }
                .chongai_selected{
                    background: url('../assets/images/chongai_selected.png') no-repeat;
                    background-size: 100% 100%
                }
                .pifu{
                    background: url('../assets/images/pifu_normal.png') no-repeat;
                    background-size: 100% 100%;
                    margin-left: 50px;
                }
                .pifu_selected{
                    background: url('../assets/images/pihu_selected.png') no-repeat;
                    background-size: 100% 100%
                }
                .pifu_chongai{
                    width: 460px;
                    background: url('../assets/images/pifu_chongai_normal.png') no-repeat;
                    background-size: 100% 100%;
                }
                .pifu_chongai_selected{
                    background: url('../assets/images/pifu_chongai_selected.png') no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
        .introduce_right{
            position: relative;
            flex: 1;
            text-align: left;

            .roleName{
                position: absolute;
                top: 180px;;
                right: 320px;
                width: 800px;
                height: 120px;
                text-align: center;
                line-height: 120px;
                font-size: 65px;
                color: #fff;
                font-weight: 700;
                background: url('../assets/images/role_name.png') no-repeat;
                background-size: 100% 100%;
                z-index: 1;
                text-align: center;
            }
            .roleTxt{
                position: absolute;
                top: 250px;
                right: 280px;
                padding: 50px;
                padding-top: 100px;
                width: 900px;
                height: 1000px;
                font-size: 50px;
                color: #000;
                font-weight: 400;
                overflow:hidden;
                background: url('../assets/images/role_txt_bg.png') no-repeat;
                background-size: 100% 100%;
                .roleIntroduce{
                    width: 100%;
                    height: 100%;
                    overflow:auto;
                }
            }
        }
        .front_btn,.back_btn{
           position:absolute;
            width: 100px;
            cursor: pointer;
            z-index: 1; 
        }
        .front_btn{
            top: 45%;
            left: 4%;
        }
        .back_btn{
            top: 32%;
            left: 89%;
        }
    }
    
// 移动端
@media screen and (max-width: 750px) {
    .bg{
        display: none;
    }
    .mb_news_bg{
        display: block;
    }
    .back_btn{
        width: 400px;
        margin-top: 400px;
    }
    .roles_content{
        .roles_article{
            width: auto;
            left: 380px;
            top: -100px;
            z-index: 9;
            li{
                float: left;
                margin: 20px 50px;
                img{
                    height: 400px;
                }
            }
        }
        .roles_lists{
            width: 100%;
            top: 800px;
            left: 0;
            .rolename(@imgTop: 200px, @imgLeft: 500px,@width:1000px) {
                img.role_list_name{
                    top: @imgTop;
                    left: @imgLeft;
                    width:@width
                }
            }
            .meiren_list,.menke_list{
                width: 80%;
                left: 450px;
                top: 550px;
                li{
                    width: 650px;
                    margin:30px;
                }
            }
            .jiancheng_list{
                width: 100%;
                height: 4080px;
                margin-top: 550px;
                overflow: hidden;
                margin-left:0px;
                background: url('../assets/images/mb_jiancheng.png') no-repeat;
                background-size: 100% 100%;
                li{
                    &:nth-child(1){
                        .rolename(900px,400px)
                    }
                    &:nth-child(2){
                        .rolename(900px,2200px)
                    }
                    &:nth-child(3){
                        .rolename(2100px,800px)
                    }
                    &:nth-child(4){
                        .rolename(2100px,2000px)
                    }
                }
            }
            .wuyi_list{
                width: 100%;
                height: 4500px;
                margin-top: 550px;
                overflow: hidden;
                margin-left:0px;
                 background: url('../assets/images/mb_wuyi.png') no-repeat;
                background-size: 100% 100%;
                li{
                    &:nth-child(1){
                        .rolename(1000px,200px)
                    }
                    &:nth-child(2){
                        .rolename(1000px,1400px)
                    }
                    &:nth-child(3){
                        .rolename(1000px,2600px)
                    }
                    &:nth-child(4){
                        .rolename(2100px,700px)
                    }
                     &:nth-child(5){
                        .rolename(2100px,2300px)
                    }
                }
            }
            .wuhu_list{
                width: 100%;
                height: 4500px;
                margin-top: 550px;
                overflow: hidden;
                margin-left:0px;
                 background: url('../assets/images/mb_wuhu.png') no-repeat;
                background-size: 100% 100%;
                li{
                    &:nth-child(1){
                        .rolename(1500px,100px)
                    }
                    &:nth-child(2){
                        .rolename(1000px,1400px)
                    }
                    &:nth-child(3){
                        .rolename(1300px,2700px)
                    }
                    &:nth-child(4){
                        .rolename(2000px,600px)
                    }
                     &:nth-child(5){
                        .rolename(2100px,2300px)
                    }
                }
            }
            .suitang_list{
                width: 100%;
                height: 4500px;
                margin-top: 550px;
                overflow: hidden;
                margin-left:0px;
                 background: url('../assets/images/mb_suitang.png') no-repeat;
                background-size: 100% 100%;
                li{
                    &:nth-child(1){
                        .rolename(1700px,200px)
                    }
                    &:nth-child(2){
                        .rolename(1100px,1700px)
                    }
                    &:nth-child(3){
                        .rolename(2100px,1100px)
                    }
                     &:nth-child(4){
                        .rolename(1700px,2600px)
                    }
                }
            }
            .nvjie_list{
                width: 100%;
                height: 4400px;
                margin-top: 650px;
                overflow: hidden;
                margin-left:0px;
                background: url('../assets/images/mb_nvjie.png') no-repeat;
                background-size: 100% 100%;
                li{
                    &:nth-child(1){
                        .rolename(1000px,700px)
                    }
                    &:nth-child(2){
                        .rolename(900px,2100px)
                    }
                    &:nth-child(3){
                        .rolename(2100px,300px)
                    }
                    &:nth-child(4){
                        .rolename(2000px,1300px)
                    }
                     &:nth-child(5){
                        .rolename(2200px,2400px)
                    }
                }
            }
        }
    }
    .roles_introduce{
        width: 90%;
        height: 4000px;
        background: url('../assets/images/mb_cartoon_detail.png') no-repeat;
        background-size: 100% 100%;
        .close_role{
            right: 80px;
            width: 280px;
        }
        .introduce_left{
            img{
                width: 1800px;
                bottom: 240px;
                left: 300px;
            }
            .meiren_img{
                width: 1600px;
                bottom: 300px;
            }
            .chongai-pihu{
                top: 200px;
                left: 350px;
                >div{
                    width: 500px;
                    height: 220px;
                    margin-bottom: 50px;
                }
                .yuan{
                    width: 600px;
                }
                .pifu_chongai{
                    width: 600px;
                    height: 250px;
                }
            }
        }
        .introduce_right{
            .roleName{
                position: absolute;
                top: 250px;;
                right: 500px;
                width: 1500px;
                height: 220px;
                line-height: 200px;
                font-size: 100px;
            }
            // .roleRead{
            //     position: absolute;
            //     font-size: 100px;
            //     top: 180px;;
            //     right: 150px;
            // }
            .roleTxt{
                top: 300px;
                right: 260px;
                padding: 80px;
                padding-top: 250px;
                width: 2000px;
                height: 1450px;
                .roleIntroduce{
                    font-size: 100px;
                }
            }
            
        }
        .front_btn,.back_btn{
           position:absolute;
            width: 200px;
            cursor: pointer;
            z-index: 1; 
        }
        .front_btn{
            top: -300px;
            left: 50%;
            transform: translateX(-50%) rotate(90deg);
        }
        .back_btn{
            left: 50%;
            top: 3400px;
            transform: translateX(-70%) rotate(90deg);
        }
    }
}
</style>

<template>
  <div class="navigation">
    <ul class="nav">
      <li>
        <a>
          <router-link to="/">
            <img src="../assets/images/tab1.png" alt="" class="pc" />
            <!-- <img src="../assets/images/mb_top.png" alt="" class="mb"> -->
          </router-link>
        </a>
      </li>
      <li>
        <a>
          <router-link to="/roles">
            <img src="../assets/images/tab2.png" alt="" class="pc" />
            <!-- <img src="../assets/images/mb_tab1.png" alt="" class="mb"> -->
          </router-link>
        </a>
      </li>
      <!-- <li>
            <a href="https://www.facebook.com/goitaquanlaogia/">
                 <img src="../assets/images/tab3.png" alt="" class="pc">
            </a>
        </li> -->
      <li>
        <a>
          <router-link to="/news">
            <img src="../assets/images/tab4.png" alt="" class="pc" />
          </router-link>
        </a>
      </li>
      <!-- <li>
            <a href="mailto:talaquanlaogia@gmail.com">
                <img src="../assets/images/tab5.png" alt="" class="pc">
            </a>
        </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: "navigation",

  props: {
    msg: String,
  },
  mounted() {
    // scroll 事件
    // let vm = this
    window.onscroll = function () {};
  },
  methods: {
    goTop() {
      this.timer = setInterval(() => {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        //   this.isTop = true
        if (osTop === 0) {
          clearInterval(this.timer);
        }
      }, 30);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang=less>
.nav {
  position: fixed;
  width: 90%;
  height: 230px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: url("../assets/images/nav_bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  padding: 0 300px;
  z-index: 99;
  li {
    margin-right: 100px;
    cursor: pointer;
    img {
      margin-top: 40px;
    }
    .mb {
      display: none;
    }
    &:nth-child(1) {
      img {
        width: 400px;
      }
    }
    &:nth-child(2) {
      img {
        width: 700px;
      }
    }
    &:nth-child(3) {
      img {
        width: 400px;
      }
    }
    &:nth-child(4) {
      img {
        width: 400px;
      }
    }
    &:nth-child(5) {
      img {
        width: 250px;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .nav {
    width: 100%;
    height: 500px;
    background: url("../assets/images/mb_nav_bg.png") no-repeat;
    background-size: 100% 100%;
    left: 0%;
    transform: translateX(0%);
    padding: 0;
    li {
      margin-left: 20px;
      margin-right: 20px;
      cursor: pointer;
      /* float: left; */
      img {
        margin-top: 100px;
      }
      /* .pc{
                display: none;
            }
            .mb{
                display: block;
            } */
      &:nth-child(1) {
        img {
          width: 650px;
        }
      }
      &:nth-child(2) {
        img {
          width: 1150px;
        }
      }
      &:nth-child(3) {
        img {
          width: 600px;
        }
      }
      &:nth-child(4) {
        img {
          width: 650px;
        }
      }
      &:nth-child(5) {
        img {
          width: 400px;
        }
      }
    }
  }
}
</style>

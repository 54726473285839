<template>
  <div id="app">
     <router-view/>
  </div>
</template>

<script>

export default {
  name: 'app',
  mounted(){
    this.calcFontSize()
  },
  methods:{
    calcFontSize:function(){
      var deviceWidth;
      setHtmlFontSize()

      if (window.addEventListener) {
        window.addEventListener('resize', function () {
            setHtmlFontSize()
        }, false)
      }
      function setHtmlFontSize () {
          deviceWidth = document.documentElement.clientWidth > 1920 ? 1920 : document.documentElement.clientWidth;
          document.getElementsByTagName('html')[0].style.cssText = 'font-size:' + deviceWidth / 19.2 + 'px !important';
      }
    }
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>


import Vue from 'vue'
import './assets/css/style.css'
import App from './App.vue'
import router from './router'
// import Toast from './components/toast'
import animated from 'animate.css' // npm install animate.css --save安装，在引入
import qs from 'qs'
import './rem.js'
// import 'lib-flexible/flexible.js'
// import store from './store'
Vue.use(animated)
// 提示框
// Vue.use(Toast)

// 序列化
Vue.prototype.qs = qs
Vue.config.productionTip = false
// console.log(process.env.BASE_URL);

const VERSION = "1.0.3";

console.log(VERSION);

// Vue.prototype._myPath= './static/images'
Vue.prototype._myPath= 'https://dl.ckhdvt.com/vietnam-web/static/images'

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')

let _myPath = `${process.env.BASE_URL}static/images`
let roleDetails = {
	"menke": [
		{
            "name":"Hạng Vũ",
            "read":"",
            "introduce":"(232 TCN - 202 TCN), biểu tự là Vũ còn gọi là Sở Bá Vương  là một nhà chính trị, một tứớng quân tài giỏi, võ công cao cường, một nhà quân sự gia xuất chúng, người có công trong việc lật đổ nhà Tần và tranh chấp thiên hạ với Hán Cao Tổ (Lưu Bang) đầu thời nhà Hán. ",
            "img":`${_myPath}/menke/detail/menke_detail_01.png`,
            "img_pifu":""
        },
        {
            "name":"Lưu Bang ",
            "read":"",
            "introduce":"Là Hán Thái Tổ Cao Hoàng Đế, người Trung Dương Lý, Ấp Phái Phong, hoàng đế khai quốc Hán Triều,là một trong những người vĩ đại đã phát triển dân tộc Hán và  nền văn hóa Hán, ông còn là chính trị gia, chiến lược gia kiệt xuất và là nhà chỉ huy xuất chúng trong lịch sử Trung Quốc. Ông có đóng góp to lớn cho sự nghiệp thống nhất Trung Quốc và sự phát triển của dân tộc Hán.",
            "img":`${_myPath}/menke/detail/menke_detail_02.png`,
            "img_pifu":""
        },
        {
            "name":"Hoắc Khứ Bệnh",
            "read":"",
            "introduce":"Là cháu ngoại tướng quân Vệ Thanh, giỏi cưỡi ngựa bắn cung, dùng binh linh hoạt, chú trọng chiến lược, không câu nệ cổ pháp, dũng mãnh quả quyết, giỏi về tập kích đường dài, đánh bất ngờ, đánh úp.",
            "img":`${_myPath}/menke/detail/menke_detail_03.png`,
            "img_pifu":""
        },
        {
            "name":"Tư Mã Ý",
            "read":"",
            "introduce":"Tư Mã ý, tự Trọng đạt, nhà chính trị ,nhà quân sự, nhà chiến lược kiệt xuất của nước Ngụy trong thời kì Tam Quốc. Người đặt nền móng cho Tây Tấn Vương Triều. Tư Mã Ý nhậm chức Đại đô đốc của Tào Ngụy, một trong 3 trọng thần được ủy thách phụ tá cho Ngụy Quốc.",
            "img":`${_myPath}/menke/detail/menke_detail_04.png`,
            "img_pifu":""
        },
        {
            "name":"Thiết Mộc Chân",
            "read":"",
            "introduce":"Thiết Mộc Chân, kha hãn Đại Mông Cổ, tôn hào 'Thành Cát Tư Hãn', nghĩa là 'bản thân sẽ đại danh tứ phương'. là chính trị gia, nhà quân sự kiệt xuất của thế giới. Nhiều lần phát động chiến tranh đánh chiếm nước ngoài, chinh phục địa vực Tây Đạt Trung Á, vùng ven biển Đen Đông Âu.",
            "img":`${_myPath}/menke/detail/menke_detail_05.png`,
            "img_pifu":""
        },
        {
            "name":"Doanh Chính",
            "read":"",
            "introduce":"Doanh Chính, hay tần Thủy Hoàng, con trai của Tần Trang Tương Vương. Là chính trị gia, chiến lược gia, cải cách gia trứ danh trong lịch sử Trung quốc. Là nhân vật chính trị có phong cách mạnh mẽ, cứng rắn, hoàn thành sự nghiệp thống nhất Hoa Hạ, cũng là vị quân chủ đầu tiên tự xưng là Hoàng Đế của Trung Quốc.",
            "img":`${_myPath}/menke/detail/menke_detail_06.png`,
            "img_pifu":"",
        },
        {
            "name":"Hàn Tín",
            "read":"",
            "introduce":'Dân tộc Hán, người Hoài Âm, là khai quốc công thần Nhà Tây Hán, nhà quân sự kiệt xuất trong lịch sử Trung Quốc. Cùng với Tiêu Hà, Trương Lương là tam kiệt thời Hán. Cùng với Bành Việt, Anh Bố được xưng là tam đại danh tướng thời Hán. trong tư tưởng quân sự của Trung Quốc thì Hàn Tín được coi là nhân vật tiêu biểu của phái "Mưu Chiến" được Tiêu Hà gọi là "Nhân Tài Kiệt Xuất".',
            "img":`${_myPath}/menke/detail/menke_detail_07.png`,
            "img_pifu":''
        },
        {
            "name":"Cao Trường Cung",
            "read":"",
            "introduce":"Cao Trường Cung: tự Trường Cung, Tự Hàng, nguyên quán Điều Điều Bột hải, là cháu của Cao Hoan Thần Vũ Đế, là đệ tử thứ 6 của Cao Trừng Văn Tương Đế, mẹ đẻ không rõ. Thuộc dòng dõi hoàng thất và là tướng  tộc Bắc Tề thời kì Nam Bắc Triều, là một trong tứ đại mỹ nam của Trung Quốc cổ đại.",
            "img":`${_myPath}/menke/detail/menke_detail_08.png`,
            "img_pifu":`${_myPath}/menke/detail/menke_pifu_08.png`,
        },
        {
            "name":"Ngụy Trưng",
            "read":"",
            "introduce":'Ngụy Trưng: tự Huyền Thành, nhà chính trị,nhà tư tưởng, nhà văn, nhà lịch sử học thời Đường. Được Trực Ngôn khuyên bảo, đã phụ tá cho Đường Thái Tông và sáng lập nên đại nghiệp "Trinh Quán chi trị", được hậu nhân đời đời ca tụng.',
            "img":`${_myPath}/menke/detail/menke_detail_09.png`,
            "img_pifu":`${_myPath}/menke/detail/menke_pifu_09.png`
        },
        {
            "name":"Cát Nhĩ Đan",
            "read":"",
            "introduce":"Phá hủy cái gọi là liên minh Vệ Lạp Đặc mà trên thực tế tự xưng là  Chuẩn Cát Nhĩ Hãn Quốc. Chinh phục Hắc Tát Khắc, tiêu diệt Diệp Nhĩ Khương Hãn Quốc, xưng hùng Tây Vực.",
            "img":`${_myPath}/menke/detail/menke_detail_10.png`,
            "img_pifu":""
        },
        {
            "name":"Vệ Thanh",
            "read":"",
            "introduce":"Vệ Thanh lần đầu xuất quân tiến đánh Long Thành, Thay đổi cục diện cuộc chiến Hán Hung, nhà Hán chuyển bại thành thắng. Là Thất Chiến Thất Diệp, thu phục được Sông Sóc, khu vực Hà Sáo, đánh bại Thiền Vu, có cống hiến to lớn trong việc mở rộng lãnh thổ phía bắc.",
            "img":`${_myPath}/menke/detail/menke_detail_11.png`,
            "img_pifu":""
        },
        {
            "name":"Phát Khâu tướng quân",
            "read":"",
            "introduce":"Phát Khâu tướng quân, hay còn gọi Phát Khâu Thiên Quan, cầm ấn đồng trên tay, trên ấn có khắc có Thiên Quan ban phúc, không kiêng kị gì, là vật thần thánh không thể thay thế trong mắt người Quật Khâu, được xưng là Có Ấn Trong Tay, Ma Quỷ Phải Tránh.",
            "img":`${_myPath}/menke/detail/menke_detail_12.png`,
            "img_pifu":""
        },
        {
            "name":"Vương Hy Chi",
            "read":"",
            "introduce":"Vương Hy Chi: tự Dật Thiếu, dân tộc Hán, là thư pháp gia trứ danh thời Tấn Đông, danh xưng 'Thư Thánh'. Thư Pháp theo nhiều loại như chữ Lệ, Thảo, Khải. Các thể loại khác nhau, nghiên cứu tỉ mỉ, mô phỏng theo thủ pháp, tạo thành một trường pháo thư pháp riêng, và có ảnh hưởng to lớn.",
            "img":`${_myPath}/menke/detail/menke_detail_13.png`,
            "img_pifu":""
        },
        {
            "name":"Kinh Kha",
            "read":"",
            "introduce":"Kinh Kha: tên là Khương, họ là Khánh. Người Triều Ca, Vệ quốc thời Chiến Quốc, là thích khách trứ danh thời cuối Chiến Quốc, là hậu nhân của đại phu Khánh phong thời Xuân Thu. Thích đọc sách, đấu kiếm, là người hào hiệp trượng nghĩa.",
            "img":`${_myPath}/menke/detail/menke_detail_14.png`,
            "img_pifu":""
        },
        {
            "name":"Tư Mã Thiên",
            "read":"",
            "introduce":"Tư Mã Thiên: Là sử gia, tán văn gia thời Tây Hán. Con trai của Tư Mã Đàm, là nhâm thái sử lệnh,Vì biện hộ cho việc thua trận của Lý Lăng Vương mà chịu hình phạt, là người kế nhiệm Trung thư lệnh. Tích cực trong việc biên tập sách sử, được hậu thế tôn xưng là Sử Thiên, Thái Sử công, bậc thày sử sách.",
            "img":`${_myPath}/menke/detail/menke_detail_15.png`,
            "img_pifu":""
        },
        {
            "name":"Vương An Thạch",
            "read":"",
            "introduce":"Vương An Thạch: tự Giới phủ, hiệu là Bán Sơn. Khánh lịch năm thứ 2, Vương An Thạch thi đậu tiến sĩ. Từng đảm nhiệm chức vụ ký xử Dương Châu, thông xử Thư Châu, có chính tích nổi bật. Hi Ninh năm thứ hai, nhậm chức tham tri chính sự, năm tiếp theo thăng chức tể tưởng,chủ trì cải cách chính trị.",
            "img":`${_myPath}/menke/detail/menke_detail_16.png`,
            "img_pifu":""
        },
        {
            "name":"Đỗ Phủ",
            "read":"",
            "introduce":"Đỗ Phủ: tự Tử Mỹ. Tự hiệu Thiếu Lăng Dã lão, là thi nhân theo chủ nghĩa hiện thực thời Đường. Đỗ Phủ cũng thường được xưng là 'Lão Đỗ'.",
            "img":`${_myPath}/menke/detail/menke_detail_17.png`,
            "img_pifu":""
        },
        {
            "name":"Tôn Vũ",
            "read":"",
            "introduce":"Tôn Vũ: tự Trường Khanh, người Lạc An, tề Quốc .Cuối thời kỳ Xuân Thu. Là nhà quân sự, chính trị gia nổi tiếng thời kỳ Xuân Thu, được tôn xưng là Binh Thánh hay Tôn tử (Tôn Vũ Tử), còn được gọi là 'Binh gia chí thánh', được khen ngợi là 'Binh gia ngàn năm có một', 'Tổ tiên Binh Pháp Đông Phương'.",
            "img":`${_myPath}/menke/detail/menke_detail_18.png`,
            "img_pifu":""
        },
        {
            "name":"Tô Thức",
            "read":"",
            "introduce":"Tô Thức, tự Tử Chiêm, tự khác Hòa Trọng, biệt hiệu Thiết quan đạo nhân, cư sĩ Đông Pha, người đời gọi là Tô Đông Pha, Tô Tiên. Dân tộc Hán, người Mi Châu, Mi Sơn (nay là Mi Sơn, thuộc tỉnh Tứ Xuyên), nguyên quán ở Loan thành Hà Bắc, là văn học gia, thư pháp gia, họa sĩ thời Bắc Tống.",
            "img":`${_myPath}/menke/detail/menke_detail_19.png`,
            "img_pifu":""
        },
        {
            "name":"Cao Tiệm Ly",
            "read":"",
            "introduce":"Cao Tiệm Ly, người Mạt Yên Chiến Quốc (nay là thôn Cao Lý, Định Hưng, Hà Bắc), là bằng hữu tốt của Kinh Kha, am hiểu kích trúc (một loại nhạc cụ cổ đại), có quan hệ tốt với Kinh Kha.",
            "img":`${_myPath}/menke/detail/menke_detail_20.png`,
            "img_pifu":`${_myPath}/menke/detail/menke_pifu_20.png`,
        },
        {
            "name":"Đông Phương Sóc",
            "read":"",
            "introduce":"Đông Phương Sóc vốn họ Trương, tự Mạn Thiến, người huyện Yếm Thứ, quận đồng bằng Tây Hán. Là văn học gia thời Tây Hán. Vào thời Hán Vũ Đế nổi danh tứ phươngm.",
            "img":`${_myPath}/menke/detail/menke_detail_21.png`,
            "img_pifu":""
        },
        {
            "name":"Tô Tần",
            "read":"",
            "introduce":"Tô Tần, tự Quý Tự, người Lạc Dương, là nhà tri thức, nhà ngoại giao và mưu lược gia nổi danh thời kỳ chiến quốc. Tô Tần cùng Trương Nghi xuất thân từ môn hạ của Quỷ Cốc Tử, theo Quỷ Cốc Tử học tập.",
            "img":`${_myPath}/menke/detail/menke_detail_22.png`,
            "img_pifu":""
        },
        {
            "name":"Lý Bạch",
            "read":"",
            "introduce":"Lý Bạch: tự Thái Bạch, biệt hiệu Thanh Liên cư sĩ, Trích tiên nhân, là thi nhân theo chủ nghĩa lãng mạn của thời Đường, được hậu nhân gọi là 'Thi Tiên'. Là người thẳng thắn, cởi mở, thích uống rượu ngâm thơ, giao lưu với bằng hữu.",
            "img":`${_myPath}/menke/detail/menke_detail_23.png`,
            "img_pifu":`${_myPath}/menke/detail/menke_pifu_23.png`,
        },
        {
            "name":"Lý Quảng",
            "read":"",
            "introduce":"Lý Quảng: dân tộc Hán, người huyện Thành kỷ quận Lũng Tây, danh tướng thời kỳ Tây hán. Nguyên Quang năm thứ 6, là tướng quân kỵ binh, dẫn hơn vạn kỵ binh đến Nhạn Môn đánh Hung Nô. Người kế nhiệm thái thú quân Bắc Bình. Hung Nô bị khuất phục, nhiều năm không giám xâm phạm, ông được xưng là Phi tướng quân. Tư Mã Thiên đánh giá là Hữu xạ tự thiên hương.",
            "img":`${_myPath}/menke/detail/menke_detail_24.png`,
            "img_pifu":""
        },
        {
            "name":"Hồ Tuyết Nham",
            "read":"",
            "introduce":"Hồ Tuyết Nham: tên thật là Hồ Quang Dung, tên hồi nhỏ là Thuận Quang, tự Tuyết Nham, sinh ra ở An Huy, Huy Châu, Tích Khê, 13 tuổi di cư tới Hàng Châu. Là thương nhân, chính trị gia, là nhân vật tiêu biểu trứ danh của Trung Quốc cận đại. Hồ Tuyết Nham có tài năng trác việt về thương nghiệp, được xưng là 'Thần Tài Sống'.",
            "img":`${_myPath}/menke/detail/menke_detail_25.png`,
            "img_pifu":""
        },
        {
            "name":"Trương Đình Ngọc",
            "read":"",
            "introduce":"Trương Đình Ngọc: tự Hành Thần, người Đồng Thành, An Huy. Con thứ của đại học sĩ Trương Anh. Khang Hi năm 39 đỗ tiến sĩ, đổi làm thứ Cát Sĩ, kiểm thảo và nhập trị Nam Thư Phòng. Cuối thời Khang Hi, tuy tác phong thành tích kém, nhưng sau này vẫn hoàn thiện chế độ quân sự. Trương Đình Ngọc sau khi chết có thụy hào là 'Văn Hòa', thờ tại Thái Miếu, là người duy nhất ở triều Thanh được thờ tại Thái Miếu.",
            "img":`${_myPath}/menke/detail/menke_detail_26.png`,
            "img_pifu":""
        },
        {
            "name":"Tác Ni",
            "read":"",
            "introduce":"Là người Mãn Châu Chính Hoàng Kỳ, một trong những người có công lập nên triều Thanh, công tước nhất đẳng, Được Thuận Trị Đế chỉ định đứng đầu 4 vị phụ chính đại thần phụ trợ cho Khang Hi. khi Thanh Thái Tổ Nỗ Nhĩ Cáp Xích tại vị, vì Sách Ni và phụ thân là Thạc Sắc Đẳng thông thạo tiếng Mãn, Mông, chữ hán, nên nhận mệnh cùng với Thạc Sắc và Hách xã, Mong cùng vào Văn Quán, được ban hiệu 'Ba Khắc Thập'.",
            "img":`${_myPath}/menke/detail/menke_detail_27.png`,
            "img_pifu":""
        },
        {
            "name":"Nạp Lan Minh Châu",
            "read":"",
            "introduce":"Tự Đoan Phạm, người Mãn Châu Chính Hoàng Kỳ, trọng thần của Khang Hi, đảm nhiệm chức tổng quản nội vụ phủ, Hình bộ thượng thư, Binh bộ thượng thư,Tả Đô Ngự Sử Đô sát viện, Đại học sĩ Võ Anh Điện và thái phó của thái tử. Nạp Lan Minh Châu đề nghị Khang Hi rút khỏi Tam Phiên, thống nhất Đài Loan và chống lại kẻ thù xâm lăng và một số chiến tích quan trọng, đem đến hiệu quả tích cực.",
            "img":`${_myPath}/menke/detail/menke_detail_28.png`,
            "img_pifu":""
        },
        {
            "name":"Chu Bồi Công",
            "read":"",
            "introduce":"Tên thật là Xương, tự Bồi Công, người kinh Môn Hồ Bắc, Một trong những cận thần quan trọng bên người Khang Hi, làm tới chức quan Đạo Đài Tham Nghị,Đăng Lại Đạo Sơn Đông, Đề Đốc Thịnh Kinh. Trong Loạn Tam Phiên, đã chiêu hàng Vương Phụ Thần, người cát cứ Bình lương, việc này được ghi chép trong tác phẩm “Giới Am Thảo”.",
            "img":`${_myPath}/menke/detail/menke_detail_29.png`,
            "img_pifu":""
        },
        {
            "name":"Can Tương",
            "read":"",
            "introduce":"Can Tương, là thợ làm kiếm nổi tiếng cuối thời Xuân Thu, tương truyền là người nước Ngô, cùng sư phụ với Âu Dã Tử, sở trường đúc binh khí. Từng làm kiếm cho Ngô Vương Hạp Lư, 'Thải ngũ sơn chi thiết tinh, lục hợp chi kim anh', vì sắt nung không chảy, nên vợ của ông là Mạc Tà đã dùng kiếm cắt móng tay vứt vào trong lò, từ đó mới có cách gọi là 'Kim thiết nãi nhu', tạo thành hai thanh kiếm là Can Tương, Mạc Tà.",
            "img":`${_myPath}/menke/detail/menke_detail_30.png`,
            "img_pifu":""
        },
        {
            "name":"Trương Hành",
            "read":"",
            "introduce":"Trương Hành, tự Bình Tử. Dân tộc Hán, người Nam Dương Tây Ngạc (nay là trấn Thạch Kiều, Nam Dương, Hà Nam), là một trong ngũ thánh Nam Dương. Cùng với Tư Mã Tương Như, Dương Hùng, Ban Cố xưng là Hán Phú tứ đại gia. là nhà thiên văn học, nhà toán học,nhà phát minh, nhà địa lý học, nhà văn của thời Đông Hán Trung Hoa. Từng đảm nhiệm đảm nhiệm chức lang trung, thái sử lệnh, trị trung, hà gian tương ở Đông Hán.",
            "img":`${_myPath}/menke/detail/menke_detail_31.png`,
            "img_pifu":""
        },
        {
            "name":"Âu Dương Tu",
            "read":"",
            "introduce":"Âu Dương Tu, tự Vĩnh Thúc, hiệu Túy Ông, biệt hiệu Lục Nhất Cư Sĩ, dân tộc hán, người Cát Châu Vĩnh Phong (nay là huyện Vĩnh Phong, Cát An, Giang Tây). Chính trị gia, văn học gia thời Bắc Tốc, nổi tiếng trong giới chính trị.",
            "img":`${_myPath}/menke/detail/menke_detail_32.png`,
            "img_pifu":""
        },
        {
            "name":"Phạm Văn Trình",
            "read":"",
            "introduce":"Phạm Văn Trình, tự là Hiến Đấu, hiệu Huy Nhạc, người Liêu Đông Trầm Dương (nay là Trầm Dương). Từng làm quan dưới 4 triều vua nhà Thanh ,Thanh thái tổ, Thanh thái tông, Thanh thế tổ, Thanh thánh tổ. Là trọng thần nhà Thanh, triều Thanh lúc khai quốc, ông là người đã đưa ra  nhiều đề xuất quy chế, nên được coi là văn thần đứng đầu.",
            "img":`${_myPath}/menke/detail/menke_detail_33.png`,
            "img_pifu":""
        },
        {
            "name":"Lý Vệ",
            "read":"",
            "introduce":"Lý Vệ, tự Hựu Giới, người Giang Nam Đồng Sơn (nay là  Lý Trại,trấn sông Đại Sa, huyện Phong, Từ Châu, Giang Tô), là danh thần đời Thanh. Năm Khang Hi 56 tuổi (năm 1717), Lý Vệ bỏ viên ngoại Lang , sau đó vào triều làm quan, qua ba đời vua Khang Hi, Ung Chính, Càn Long. Được hoàng đế Ung Chính coi trọng, từng đảm nhiệm hộ bộ lang trung, Binh bộ Thượng thư, tổng đốc Trực Lệ, và các chức khác.",
            "img":`${_myPath}/menke/detail/menke_detail_34.png`,
            "img_pifu":""
        },
        {
            "name":"Tào Tuyết Cần",
            "read":"",
            "introduce":"Tào Tuyết Cần, danh là Triêm, tự Mộng Nguyễn, hiệu Tuyết Cần, biệt hiệu Cần Khê,Cần Phố là tác giả của cuốn tiểu thuyết Hồng Lâu Mộng, một trong tứ đại danh tác của văn học cổ điển Trung Quốc. Nguyên quán ở Liêu Ninh, Thiết Lĩnh, sinh ở Nam Kinh, chừng 13 tuổi thì trở về Bắc Kinh. Tào Tuyết Cần xuất thân trong gia đình thuộc tầng lớp nô bộc bạch kỳ nội vụ phủ thời thanh, là cháu trai của Tào Dần, chủ hàng dệt may Giang Ninh, con trai của Tào Ngung.",
            "img":`${_myPath}/menke/detail/menke_detail_35.png`,
            "img_pifu":`${_myPath}/menke/detail/menke_pifu_35.png`,
        },
        {
            "name":"Hồng Thừa Trù",
            "read":"",
            "introduce":"Tự Ngạn Diễn, tự là Hanh Cửu, người nam An Anh, Tuyền Châu, Phúc Kiến (nay là Hà mỹ thôn Lương Sơn,Trấn Anh Đô). năm Vạn Lịch 44 đỗ tiến sĩ, được phong làm chính sứ tham chính Thiểm Tây, Năm Sùng Trinh thì làm tới chức Binh bộ thượng thư, Kế Liêu tổng đốc, Sau khi bại trận Tùng Cẩm bị nhà Thanh bắt giữ làm tù binh, sau đầu hàng trở thành Đại học sĩ người hán dưới Triều Thanh.",
            "img":`${_myPath}/menke/detail/menke_detail_36.png`,
            "img_pifu":""
        },
        {
            "name":"Niên Canh Nghiêu",
            "read":"",
            "introduce":"Tự Lượng Công,hiệu  là Song Phong, danh tướng nhà Thanh. Nguyên quán huyện Hoài Viễn, phủ Phượng Dương (nay là An Huy), sau này đi theo hán quân Tương Hoàng Kỳ, sống ở thời Khanh Hi, Ung Chính , xuất thân tiến sĩ, tới Tứ Xuyên làm quan tổng đốc, Tổng đổng Xuyên Thiểm, Đại tướng quân Phù Xa, còn được gia phong Thái Bảo, công thần nhất đẳng, và các chức quan hiển hách.",
            "img":`${_myPath}/menke/detail/menke_detail_37.png`,
            "img_pifu":""
        },
        {
            "name":"Lâm Tắc Từ",
            "read":"",
            "introduce":"Người huyện Hầu Quan, Phúc Kiến, tự Nguyên Phù, Thiếu Mục, Thạch Lân, vãn hiệu là kỳ Thôn lão Nhân, Kỳ Thôn Thoái Tẩu, Thất Thập Nhị Phong Thoái Tẩu, Bình Tuyền cư sĩ, Lịch xã tản Nhân. Là nhà chính trị, nhà tư tưởng, thi nhân thời Thanh, làm quan tới chức nhất phẩm, nhận chức Tổng đốc Hồ Quảng, hai lần đảm nhiệm chức khâm sai đại thần. Chủ trương nghiêm cấm nha phiến, là 'Anh hùng dân tộc Trung hoa'.",
            "img":`${_myPath}/menke/detail/menke_detail_38.png`,
            "img_pifu":""
        },
        {
            "name":"Hoa An",
            "read":"",
            "introduce":"Thư đồng của phủ Hoa Thái Sư, văn võ song toàn, trên phố đồn đại rằng có một đại tài từ họ Đường vì theo đuổi nha hoàn mà cải trang thành hạ nhân lẻn vào Hoa phủ.",
            "img":`${_myPath}/menke/detail/menke_detail_39.png`,
            "img_pifu":`${_myPath}/menke/detail/menke_pifu_39.png`
        },
        {
            "name":"Tiêu Kiếm",
            "read":"",
            "introduce":"Tinh cách cách vốn là viên ngọc trên tay lão phật gia.Phò Mã Tiêu Kiếm là chồng của Tinh Cách Cách.Tiêu Kiếm, vốn tên là Phương Nghiêm,  nhất kiếm nhất tiêu Nam Bắc tung hoành. Chàng có một mối thù khác cốt ghi tâm và luôn đi tìm muội muội thất lạc! hành tung bất định, không để lại dấu vết, người này là một ẩn số,không đơn giản.",
            "img":`${_myPath}/menke/detail/menke_detail_40.png`,
            "img_pifu":`${_myPath}/menke/detail/menke_pifu_40.png`
        },
        {
            "name":"Kỷ Vân",
            "read":"",
            "introduce":"Kỷ Vân, tự là Hiểu Lam, tên chữ là Xuân Phàm, vãn hiệu Thạch Vân, đạo hiệu Quan Dịch Đạo Nhân, ở huyện Hiến (nay là Hà Bắc, thành phố Thương Châu). Là nhà văn, nhà chính trị đời nhà Thanh, dưới thời Càn Long từng đảm nhiệm chức vụ Tả đô Ngự sử,Binh bộ thương thư, Lễ bộ Thượng thư, cùng Đại học sĩ và thầy giáo của thái tử quản lý Quốc Tử Giám sau đó từ chức, từng đảm nhiệm tổng biên soạn 'Tứ Khố Toàn Thư'.",
            "img":`${_myPath}/menke/detail/menke_detail_41.png`,
            "img_pifu":`${_myPath}/menke/detail/menke_pifu_41.png`
        },
        {
            "name":"Lưu Dung",
            "read":"",
            "introduce":"Lưu Dung, tự Sùng Như, hiệu Thạch An, là chính trị gia , thư pháp gia triều Thanh và là trưởng tử của đại học sĩ Lưu Thống Huân. Nguyên quán ở An Huy Đãng Sơn, sinh ra ở Chu Thành, Sơn Đông. Từng đảm nhiệm vị trí Thứ cát sĩ ở Hàn Lâm Viện, tri phủ  của phủ Thái Nguyên, tri phủ Giang Ninh, nội các học sĩ, Đại học sĩ Thể Nhất Các và các chức khác, tuân thủ luật pháp, công tôn liêm minh.",
            "img":`${_myPath}/menke/detail/menke_detail_42.png`,
            "img_pifu":`${_myPath}/menke/detail/menke_pifu_42.png`
        },
        {
            "name":"Trịnh Bản Kiều",
            "read":"",
            "introduce":"Trịnh Bản Kiều, tên thật là Trịnh Nhiếp, tự Khắc Nhu, hiệu Lý An, biệt hiệu Bản Kiệu , người đời gọi là Bản Kiều tiên sinh, người Giang Tô Hưng Hóa, nguyên quán Tô Châu. Là tú tài thời Khang Hi, năm Ung Chính thứ 10 thi đỗ cử nhân, đỗ tiến sĩ năm Càn Long thứ nhất(1736).Huyện lệnh Phạm Huyện và Duy Huyện ,(tỉnh)Sơn Đông, thành tích ưu tú, sau này sống ở Dương Châu, lấy bán tranh làm nghề kiếm sống, là nhân vật tiêu biểu của 'Dương Châu bát quái'.",
            "img":`${_myPath}/menke/detail/menke_detail_43.png`,
            "img_pifu":`${_myPath}/menke/detail/menke_pifu_43.png`
        },
        {
            "name":"Trần Cận Nam",
            "read":"",
            "introduce":"Trần Cận Nam, lợi dụng tín ngưỡng 'Huyền thiên thượng đế để thành lập một tổ chức bí mật, liên kết những người xa lạ, lấy trời làm cha, lấy đất làm mẹ, cùng với Trịnh Thành Công có tôn hiệu 'Vạn Vân Long' nên được gọi là anh cả Long đầu lãnh đạo tổ chức phản Thanh phục Minh. Sau này không ngừng phát triển ra thành nhiều môn phái, trở thành thế lực khiến nhà Thanh đau đầu, Thiên Địa Hội.",
            "img":`${_myPath}/menke/detail/menke_detail_44.png`,
            "img_pifu":`${_myPath}/menke/detail/menke_pifu_44.png`
        },
        {
            "name":"Lưu Bị",
            "read":"",
            "introduce":"Từ Huyền Đức, người Yêu Châu cuối thời Đông Hán, hậu duệ của Trung Sơn Thanh Vương Lưu Thắng thời Tây Hán, là Hoàng Đế sáng lập nước Thục Hán, một chính trị gia lỗi lạc thời kỳ Tam Quốc. Là một người hào hiệp trọng nghĩa, có tài dùng người, tính cách cứng rắn không chịu khuất phục trước khó khăn, cuối cùng dựng nên đại nghiệp.",
            "img":`${_myPath}/menke/detail/menke_detail_45.png`,
            "img_pifu":""
        },
        {
            "name":"Mặc Tử",
            "read":"",
            "introduce":"Là người sáng lập ra học phái Mặc Tử, ông cũng là nhà tư tưởng, nhà giáo dục, nhà quân sự, nhà khoa học nổi tiếng thời chiến quốc.",
            "img":`${_myPath}/menke/detail/menke_detail_46.png`,
            "img_pifu":''
        },
        {
            "name":"Lão Tử",
            "read":"",
            "introduce":"Họ Lý tên Nhĩ, tự Bá Dương. Đạo Gia Tông Sư, một nhà triết học nhà tư tưởng thời Xuân Thu Trung Quốc.",
            "img":`${_myPath}/menke/detail/menke_detail_47.png`,
            "img_pifu":''
        }
    ],
    "wuyi":[
        {
            "name":"Ngô Dụng",
            "read":"",
            "introduce":"Ngô Dụng, xếp thứ 3 Lương Sơn Bạc tương ứng với Thiên Cơ Tinh, hiểu biết kinh luân, thông hiểu văn thao vũ lược, túc trí đa mưu, thường tự so sánh mình với Gia Cát Lượng, đạo hiệu 'Gia Lượng Tiên Sinh', được người đời gọi là 'Đa mưu túc trí'.",
            "img":`${_myPath}/wuyi/detail/wuyi_detail_01.png`,
            "img_pifu":""
        },
        {
            "name":"Công Tôn Thắng",
            "read":"",
            "introduce":"Công Tôn Thắng, biệt hiệu Nhập Vân Long, đạo hiệu Nhất Thanh, người Kế Châu. Trong Lương Sơn Đại Hội, đứng thứ 4, tương ứng với Thiên Nhàn Tinh, đảm nhiệm chức quân sư cơ mật. Trở về Kế Châu cùng Chinh Phương Lạp , chuyên tâm tu đạo.",
            "img":`${_myPath}/wuyi/detail/wuyi_detail_02.png`,
            "img_pifu":""
        },
        {
            "name":"Lâm Xung",
            "read":"",
            "introduce":"Lâm Xung, biệt hiệu là Báo Tử Đầu, người Đông Kinh, vốn dĩ là giáo đầu Thương Bổng của 80 vạn vệ binh. Tham dự hàng loạt chiến dịch Lương Sơn, lập được nhiều công lao. Trong Lương Sơn Đại Hội, đứng thứ 6, ứng với Thiên Hùng Tinh, đứng hàng Mã Quân Ngũ Hổ Tướng, gác trại chánh Tây.",
            "img":`${_myPath}/wuyi/detail/wuyi_detail_03.png`,
            "img_pifu":""
        },
        {
            "name":"Tống Giang",
            "read":"",
            "introduce":"Tống Giang, tự Công Minh, tên hiệu là Hô Bảo Nghĩa, Kỳ Thời Vũ, Hiếu Nghĩa Hắc tam Lang. Nguyên là Áp Ti, huyện vận Thành, Sơn Đông, dáng người thấp bé, vẻ ngoài ngăm đen, nhà lãnh đạo của khởi nghĩa Lương Sơn, là người đứng đầu 100 anh hùng Lương Sơn Bạc, Thiên Khôi Tinh đứng đầu 36 vị sao Thiên Cang.",
            "img":`${_myPath}/wuyi/detail/wuyi_detail_04.png`,
            "img_pifu":""
        },
        {
            "name":"Lư Tuấn Nghĩa",
            "read":"",
            "introduce":"Lư Tuấn Nghĩa, biệt hiệu là 'Ngọc Kỳ lân', võ nghệ cao cường, côn thuật thiên hạ vô song, người giang hồ xưng là 'Hà Bắc tam Tuyệt'. Vốn là phú thương, đại tài chủ, viên ngoại Hà Bắc, bị Ngô Dụng lợi dụng gian tình giữa Lý Cố và Giả Thị nên buộc phải lên Lương Sơn, sau trở thành thủ lĩnh thứ hai của Lương Sơn Bạc.",
            "img":`${_myPath}/wuyi/detail/wuyi_detail_05.png`,
            "img_pifu":""
        }
    ],
    "wuhu":[
        {
            "name":"Mã Siêu",
            "read":"",
            "introduce":"Là danh tướng cuối thời Đông Hán, có công lao trấn áp ngoại tộc, được xưng làm “Thần Vũ Tướng Quân”.",
            "img":`${_myPath}/wuhu/detail/wuhu_detail_01.png`,
            "img_pifu":""
        },
        {
            "name":"Trương Phi",
            "read":"",
            "introduce":"Tự Dực Đức, danh tướng Thục Hán thời Tam Quốc, vừa dũng lại có mưu, tài năng xuất chúng, một người có thể địch được vạn người.",
            "img":`${_myPath}/wuhu/detail/wuhu_detail_02.png`,
            "img_pifu":""
        },
        {
            "name":"Triệu Vân",
            "read":"",
            "introduce":"Tự Tử Long, là danh tướng Thục Hán thời Tam Quốc, thân cao tám tấc, tư nghiên hùng vĩ, được xưng làm “Thường Thắng Tướng Quân”.",
            "img":`${_myPath}/wuhu/detail/wuhu_detail_03.png`,
            "img_pifu":""
        },
        {
            "name":"Quan Vũ",
            "read":"",
            "introduce":"Tự Vân Trường, là danh tướng Thục Hán thời Tam Quốc, được nhân dân tôn làm  “Quan Công”,được người đời sau tôn thành “Vũ Thánh”.",
            "img":`${_myPath}/wuhu/detail/wuhu_detail_04.png`,
            "img_pifu":""
        },
        {
            "name":"Hoàng Trung",
            "read":"",
            "introduce":"Tự Hán Thăng, là danh tướng cuối thời Đông Hán, võ nghệ cao cường, là lão tướng nhưng sức địch vạn người, được người đời sau gọi là một trong những “Ngũ Hổ Thượng Tướng”.",
            "img":`${_myPath}/wuhu/detail/wuhu_detail_05.png`,
            "img_pifu":""
        }
    ],
    "suitang":[
        {
            "name":"Uất Trì Cung",
            "read":"",
            "introduce":"Kính Đức biết đến với vai trò là một võ tướng và công thần khai quốc của nhà Đường,Ngạc Quốc Công, Ông là một trong 24 công thần được vẽ chân dung trên Lăng Yên Các.",
            "img":`${_myPath}/suitang/detail/suitang_detail_01.png`,
            "img_pifu":""
        },
        {
            "name":"Trình Giảo Kim",
            "read":"",
            "introduce":"Tên Giảo Kim, sau đó đổi tên thành Tri Tiết, Tự Nghĩa Trinh, Đại tướng khai Quốc thời nhà Đường, là một trong 24 đại công thần được vẽ chân dung trên Lăng Yên Các.",
            "img":`${_myPath}/suitang/detail/suitang_detail_02.png`,
            "img_pifu":""
        },
        {
            "name":"Tần Quỳnh",
            "read":"",
            "introduce":"Tự Thúc Bảo, là danh tướng cuối thời Tùy đầu Thời Đường. Ông là một trong 24 công thần được vẽ chân dung trên Lăng Yên Các.",
            "img":`${_myPath}/suitang/detail/suitang_detail_03.png`,
            "img_pifu":""
        },
        {
            "name":"La Thành",
            "read":"",
            "introduce":"La Thành, xếp thứ 7 trong 18 vị anh hùng thế kiệt thời Tùy Đường, tinh thông Thương pháp, diện mạo anh tuấn, rất hiếm khi cười nên được người đời tặng biệt hiệu “ Mặt lạnh thương hàn”.",
            "img":`${_myPath}/suitang/detail/suitang_detail_04.png`,
            "img_pifu":""
        },
    ],
    "nvjie":[
        {
            "name":"Lương Hồng Ngọc",
            "read":"",
            "introduce":"Nguyên quán An Huy, Trì Trâu, sống ở Hoài An, Giang Tô, là nữ anh hùng trứ danh thời Tống, tổ phụ cùng phụ thân đều xuất thân võ tướng, Lương Hồng Ngọc theo anh trai và cha luyện võ từ nhỏ. Trong sử sách không có kuỳ danh, chỉ gọi Lương Thị.",
            "img":`${_myPath}/nvjie/detail/nvjie_detail_01.png`,
            "img_pifu":`${_myPath}/nvjie/detail/nvjie_pifu_01.png`,
        },
        {
            "name":"Phán Lê Hoa",
            "read":"",
            "introduce":"Là người trong thời Trinh Quán Đại Đường, nàng nổi tiếng vì cùng với Tiết Đinh Sơn bình định biên loạn Tây Bắc, tiến quân Sa Trường. Ảnh hưởng sâu sắc đến đời sau.",
            "img":`${_myPath}/nvjie/detail/nvjie_detail_02.png`,
            "img_pifu":`${_myPath}/nvjie/detail/nvjie_pifu_02.png`,
        },
        {
            "name":"Hoa Mộc Lan",
            "read":"",
            "introduce":"Hoa Mộc Lan là một nhân vật nữ anh hùng trong truyền thuyết dân gian Trung Quốc, trung hiếu tiết nghĩa, thay cha tòng quân đánh bại quân xâm lược, lưu danh thiên cổ, được hoàng đế thời Đường truy phong làm 'Hiếu liệt chiếu tướng'.",
            "img":`${_myPath}/nvjie/detail/nvjie_detail_03.png`,
            "img_pifu":`${_myPath}/nvjie/detail/nvjie_pifu_03.png`,
        },
        {
            "name":"Tần Lương Ngọc",
            "read":"",
            "introduce":"Nữ tướng nổi tiếng cuối thời Minh, có chồng Mã Thiên Thừa là dòng dõi Phục Ba tướng quân Mã Viện nhà Hán, được lập tượng đá phong Uy Sứ (Tên thường gọi là Thổ Ti). Sau khi Mã Thiên Thừa bị hại, vì con trai là Mã Tường lân tuổi nhỏ, nên Tần Lương Ngọc đã đảm nhiệm chức vụ của chồng.",
            "img":`${_myPath}/nvjie/detail/nvjie_detail_04.png`,
            "img_pifu":`${_myPath}/nvjie/detail/nvjie_pifu_04.png`,
        },
        {
            "name":"Mục Quế Anh",
            "read":"",
            "introduce":"Là con gái của Mục Vũ trại chủ Mục Kha Trại, võ nghệ siêu quần, cơ trí dũng cảm, nghe nói được thần nữ truyền lại thuật thần tiễn, phi đao. Trong một trận giao chiến, bà bắt được Dương Tông Bảo, sau đó thành thân với ông và trở thành một thành viên của Dương gia tướng, trở thành nữ tướng kiệt xuất của Dương Môn.",
            "img":`${_myPath}/nvjie/detail/nvjie_detail_05.png`,
            "img_pifu":`${_myPath}/nvjie/detail/nvjie_pifu_05.png`,
        }
    ],
    "jiancheng":[
        {
            "name":"Triệu Cao",
            "read":"",
            "introduce":"Thừa tướng của Nhị Thế Hoàng Đế Triều Tần, Triệu Cao Bản là họ hàng xa của tôn thất Tần Quốc, nhận chức Trung xa phủ lệnh, Phù tỳ lệnh xư, 'Quản sự hơn 20 năm'. Sau khi Tần Thủy Hoàng mất, Triệu Cao phát động chính biến Sa Khâu, cùng với thừa tướng Lý Tư hợp mưu giả mạo chiếu thư ép trưởng tử của Tần Thủy Hoàng, Phù Tô tự sát, lập ấu tử Hồ Lợi làm Vua, hiệu Tần Nhị Thế, nhận chức Nhâm lang trung lệnh.",
            "img":`${_myPath}/jiancheng/detail/jiancheng_detail_01.png`,
            "img_pifu":`${_myPath}/jiancheng/detail/jiancheng_pifu_01.png`, 
        },
        {
            "name":"Ngụy Trung Hiền",
            "read":"",
            "introduce":"Tự Hoàn Ngô, người Túc Ninh, bắc Trục Lệ (nay là huyện Túc Ninh, Thương Châu, Hà Bắc), dân tộc Hán, là hoạn quan cuối thời Minh. Sau khi tự cung đổi tên thành Lý Tiến Trung, lấy họ đệm của tài tử Vương Thị, sau khi đảm nhiệm chức bỉnh bút thái giám, thì đổi lại tên họ, được Hoàng Đế ban cho cái tên Ngụy Trung Hiền.",
            "img":`${_myPath}/jiancheng/detail/jiancheng_detail_02.png`,
            "img_pifu":`${_myPath}/jiancheng/detail/jiancheng_pifu_02.png`, 
        },
        {
            "name":"Lý Liên Anh",
            "read":"",
            "introduce":"Là thái giám tổng quản của Từ Hi triều Thanh, theo hầu Thái hậu Từ Hi gần 53 năm, là hoạn quan có quyền thế nhất cuối nhà Thanh, cũng là người đầu tiên gọi Từ Hi thái hậu là 'Lão phật gia'. Năm Tuyên Thống thứ nhất, khi đã 61 tuổi, sau khi giữ đạo hiếu 100 ngày cho Từ Hi Thái Hậu thì về ở ẩn.",
            "img":`${_myPath}/jiancheng/detail/jiancheng_detail_03.png`,
            "img_pifu":`${_myPath}/jiancheng/detail/jiancheng_pifu_03.png`, 
        },
        {
            "name":"Tần Cối",
            "read":"",
            "introduce":"Tự Hội Chi, sống ở Hoàng Châu, quê ở Giang Ninh (nay là Nam Kinh, Giang Tô). Trong những năm đầu triều đại Nam Tống, là nhân vật tiêu biểu của phái chủ hòa, là gian thần. Năm Tịnh Khang thứ hai, vì thượng thư Kim Soái phản đối Lập Trương Bang Xương, cùng Huy,Khâm hai đế bị bắt đến Kim,và được Thát Lại tin dùng.",
            "img":`${_myPath}/jiancheng/detail/jiancheng_detail_04.png`,
            "img_pifu":`${_myPath}/jiancheng/detail/jiancheng_pifu_04.png`, 
        }
    ],
    "meiren":[
        {
            "name":"Trần Viên Viên",
            "read":"",
            "introduce":"Nghệ nữ tuyệt sắc Hạnh Hoa Lâu, sắc nghệ song toàn, danh chấn Giang Tả, vẻ ngoài thanh tao lịch sự, thướt tha như gió. #Thăm hỏi# Nghệ nữ tuyệt sắc Hạnh Hoa Lâu",
            "img":`${_myPath}/meiren/detail/meiren_detail_01.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_01.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Điêu Thuyền",
            "read":"",
            "introduce":"Dáng người thướt tha, dung mạo thanh tao, nho nhã kiêu sa, có biệt danh là Bích Nguyệt. #Phúc lợi VIP4# Một trong tứ đại mỹ nhân",
            "img":`${_myPath}/meiren/detail/meiren_detail_02.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_02.png`, 
            "img_pifu":`${_myPath}/meiren/detail/meiren_pifu_02.png`, 
            "img_pifu_chongai":`${_myPath}/meiren/detail/meiren_pifuchongai_02.png`, 
        },
        {
            "name":"Đổng Tiểu Uyển",
            "read":"",
            "introduce":"Vũ nữ tuyệt sắc Hạnh Hoa Lâu, tài nghệ xuất chúng, ngâm thơ đối câu, cầm kỳ thi họa, tất cả đều tinh thông. #Thăm hỏi# Vũ nữ tuyệt sắc Hạnh Hoa Lâu",
            "img":`${_myPath}/meiren/detail/meiren_detail_03.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_03.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Hồ Thanh Dương",
            "read":"",
            "introduce":"Con gái thần y Hồ Thanh Ngưu, đa sầu đa cảm, quan tâm người khác. #Thăm hỏi# Bảo muội đương thế Thần Y Hồ Thanh",
            "img":`${_myPath}/meiren/detail/meiren_detail_04.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_04.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Hoa Kiến Tu",
            "read":"",
            "introduce":"Đôi mắt nàng sâu lặng như làn nước mùa thu, đôi lông mày thanh tú như dáng núi mùa xuân, trước dung mạo thần tiên của nàng cũng phải khiến cho Hoa ghen thua thắm liễu hờn kém xanh.#Thế lực đạt 500000 mở khóa#Đệ nhất mỹ nữ Ngũ Đại",
            "img":`${_myPath}/meiren/detail/meiren_detail_05.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_05.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Kim Tương Ngọc",
            "read":"",
            "introduce":"Bông hoa khách sạn thị trấn, hoạt bát đáng yêu, giỏi nấu nướng, là bậc hiền thê điển hình. #Thế lực đạt 50000 mở khóa# Khách sạn nhất chi hoa ",
            "img":`${_myPath}/meiren/detail/meiren_detail_06.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_06.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Lý Linh Nhi",
            "read":"",
            "introduce":"Con gái cả Lý Chính Đạo, là một trong những mỹ nhân nổi tiếng kinh thành, khiến quý nhân trong thành đều thèm nhỏ nước dãi. #Thăm hỏi# đại tiểu thư phủ Thượng Thư",
            "img":`${_myPath}/meiren/detail/meiren_detail_07.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_07.png`, 
            "img_pifu":`${_myPath}/meiren/detail/meiren_pifu_07.png`, 
            "img_pifu_chongai":`${_myPath}/meiren/detail/meiren_pifuchongai_07.png`
        },
        {
            "name":"Lý Sư Sư",
            "read":"",
            "introduce":"Ca nữ tuyệt sắc Hạnh Hoa Lâu, vẻ ngoài đoan trang, dung mạo tuyệt sắc, tính khí thẳn thắn. #Thăm hỏi# Tuyệt sắc ca cơ Hạnh Hoa Lâu",
            "img":`${_myPath}/meiren/detail/meiren_detail_08.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_08.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Lâm Đại Ngọc",
            "read":"",
            "introduce":"Xuất thân từ danh gia quý tộc, sau do phụ thân mất sớm, nên luôn cao ngạo tự kiêu, không coi ai ra gì. #Thăm hỏi# Kim Lăng Thập nhị thoa chi nhất ",
            "img":`${_myPath}/meiren/detail/meiren_detail_09.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_09.png`, 
            "img_pifu":`${_myPath}/meiren/detail/meiren_pifu_09.png`,
            "img_pifu_chongai":""
        },
        {
            "name":"Liễu Như Thị",
            "read":"",
            "introduce":"Tài nữ tuyệt sắc Hạnh Hoa Lâu, cá tính kiên cường, chính trực thông minh, giỏi thơ ca. #Thăm hỏi# Tuyệt sắc tài nữ Hạnh Hoa Lâu",
            "img":`${_myPath}/meiren/detail/meiren_detail_10.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_10.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Lữ Trĩ",
            "read":"",
            "introduce":"Vốn là lữ tiểu thư hiền lương thục đức, nhưng lại trở thành Lữ thái hậu thủ đoạn nhẫn tâm.#Phúc lợi VIP10# Lữ Thái Hậu",
            "img":`${_myPath}/meiren/detail/meiren_detail_11.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_11.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Song Nhi",
            "read":"",
            "introduce":"Mỹ nữ Thiên Địa Hội, thiên chân khả ái, trung can nghĩa đảm, lòng dạ bao dung và còn biết võ nghệ. #Thăm hỏi# mỹ nữ Thiên Địa Hội",
            "img":`${_myPath}/meiren/detail/meiren_detail_12.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_12.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Tô Tiểu Tiểu",
            "read":"",
            "introduce":"Hoa khôi hạnh hoa lâu, mỹ nhân tuyệt thế, được nhiều người yêu mến, nhưng luôn trong trạng thái tâm sự nặng nề .#Thăm hỏi# Hoa khôi Hạnh Hoa Lâu tuyệt sắc",
            "img":`${_myPath}/meiren/detail/meiren_detail_13.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_13.png`, 
            "img_pifu":`${_myPath}/meiren/detail/meiren_pifu_13.png`, 
            "img_pifu_chongai":`${_myPath}/meiren/detail/meiren_pifuchongai_13.png`
        },
        {
            "name":"Vương Chiêu Quân",
            "read":"",
            "introduce":"Một trong tứ đại mỹ nhân, nho nhã hào phóng, xinh đẹp động lòng người, giỏi cầm kỳ, có biệt danh là Lạc Nhạn. #Phúc lợi VIP6# Một trong tứ đại mỹ nhân",
            "img":`${_myPath}/meiren/detail/meiren_detail_14.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_14.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Võ Mị Nương",
            "read":"",
            "introduce":"Võ Tắc Thiên túc trí đa mưu, tinh thông văn sử, rất có thi tài. #Thế lực đạt 1000000 mở khóa# Nhất Đại Nữ Đế",
            "img":`${_myPath}/meiren/detail/meiren_detail_15.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_15.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Tây Thi",
            "read":"",
            "introduce":"Vẻ đẹp trời ban, nhan sắc tựa Thần Tiên, tăng chút cân thì sợ mập, giảm chút cân thì gầy, có biệt danh là Trầm Ngư. #Phúc lợi VIP2# Một trong tứ đại mỹ nhân",
            "img":`${_myPath}/meiren/detail/meiren_detail_16.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_16.png`, 
            "img_pifu":`${_myPath}/meiren/detail/meiren_pifu_16.png`, 
            "img_pifu_chongai":`${_myPath}/meiren/detail/meiren_pifuchongai_16.png`
        },
        {
            "name":"Hân Ninh",
            "read":"",
            "introduce":"Cách cách đương triều, viên ngọc quý của Ung Thân Vương, do được phụ thân cưng chiều nên rất bướng bỉnh #Thăm hỏi# Cách cách phủ Thân Vương",
            "img":`${_myPath}/meiren/detail/meiren_detail_17.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_17.png`, 
            "img_pifu":`${_myPath}/meiren/detail/meiren_pifu_17.png`, 
            "img_pifu_chongai":`${_myPath}/meiren/detail/meiren_pifuchongai_17.png`
        },
        {
            "name":"Từ Oanh Oanh",
            "read":"",
            "introduce":"Con gái Từ tài, dưới sự dạy bảo của phụ thân nên rất có tri thức và hiểu biết lễ nghĩa, lại xinh đẹp thông minh. #Thăm hỏi# Con gái Tài Tử tư thục tiên sinh",
            "img":`${_myPath}/meiren/detail/meiren_detail_18.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_18.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Tiết Bảo Thoa",
            "read":"",
            "introduce":"Viên ngọc quý trên tay Tiết Gia, dung mạo đoan trang, cử chỉ thanh tao lịch sự, học rộng tài cao, học rộng hiểu nhiều . #Thăm hỏi#Kim Lăng Thập nhị thoa chi nhất ",
            "img":`${_myPath}/meiren/detail/meiren_detail_19.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_19.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Dương Ngọc Hoàn",
            "read":"",
            "introduce":"Một trong tứ đại mỹ nhân, vẻ đẹp nghiêng nước nghiêng thành, giỏi ca múa, có biệt danh là Tu Hoa #Phúc lợi VIP8# Một trong tứ đại mỹ nhân",
            "img":`${_myPath}/meiren/detail/meiren_detail_20.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_20.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Trường Bình Công Chúa",
            "read":"",
            "introduce":"Công chúa tiền triều, có tâm phục quốc, đáng tiếc là khát vọng không thể thực hiện nên ngày nào cũng đẫm nước mắt. #Thăm hỏi#Mỹ nữ thiên địa hội",
            "img":`${_myPath}/meiren/detail/meiren_detail_21.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_21.png`, 
            "img_pifu":`${_myPath}/meiren/detail/meiren_pifu_21.png`,
            "img_pifu_chongai":""
        },
        {
            "name":"Triệu Phi Yến",
            "read":"",
            "introduce":"Chị gái Triệu Thị, giỏi ca múa, giọng ca mềm mại, dáng múa nhẹ nhàng tựa như Lăng Ba tiên tử.  #Thăm hỏi# Chị gái Triệu Thị",
            "img":`${_myPath}/meiren/detail/meiren_detail_22.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_22.png`, 
            "img_pifu":`${_myPath}/meiren/detail/meiren_pifu_22.png`,
            "img_pifu_chongai":""
        },
        {
            "name":"Triệu Hợp Đức",
            "read":"",
            "introduce":"Em gái Triệu Thị, cơ thể đẫy đà, làm da mịn màng, vô cùng xinh đẹp, có phong thái rất khác tỷ tỷ. #Thăm hỏi# Em gái Triệu Thị Lý Sinh",
            "img":`${_myPath}/meiren/detail/meiren_detail_23.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_23.png`, 
            "img_pifu":`${_myPath}/meiren/detail/meiren_pifu_23.png`,
            "img_pifu_chongai":""
        },
        {
            "name":"Triệu Tiểu Tu",
            "read":"",
            "introduce":"Con gái Triệu Tri Phủ, thanh thú thoát tục, nhưng do hoàn cảnh sa sút nên luôn giữ vẻ mặt lạnh lùng. #Thăm hỏi# con gái tri phủ hàm hoan Triệu Văn Tuyên",
            "img":`${_myPath}/meiren/detail/meiren_detail_24.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_24.png`, 
            "img_pifu":`${_myPath}/meiren/detail/meiren_pifu_24.png`, 
            "img_pifu_chongai":`${_myPath}/meiren/detail/meiren_pifuchongai_24.png`
        },
        {
            "name":"Chúc Anh Đài",
            "read":"",
            "introduce":"Đại tiểu thư Chúc Gia Trang, hoạt bát lanh lợi, ngây thơ chân chất, rất hiếu học, nữ giả nam trang lại có làm sao. #Thế lực đạt 200000 mở khóa# Đại tiểu thư Trúc Gia Trang",
            "img":`${_myPath}/meiren/detail/meiren_detail_25.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_25.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Thượng Quan Uyển Nhi",
            "read":"",
            "introduce":'Thượng Quan Uyển Nhi,"Nữ tể tướng", Tài nữ trứ danh, họ kép Thượng Quan, thiếu tự Uyển nhi, còn gọi là thượng quan Chiêu Dung."',
            "img":`${_myPath}/meiren/detail/meiren_detail_26.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_26.png`, 
            "img_pifu":`${_myPath}/meiren/detail/meiren_pifu_26.png`, 
            "img_pifu_chongai":''
        },
        {
            "name":"Ngu Cơ",
            "read":"",
            "introduce":"Dung Nhan khuynh Quốc, Tài sắc vẹn toàn, được người đời gọi là Ngu Mỹ Nhân.",
            "img":`${_myPath}/meiren/detail/meiren_detail_27.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_27.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Trác Văn Quân",
            "read":"",
            "introduce":"Tài nữ trứ danh thời hán, Con gái Trác vương tôn, nhan sắc xinh đẹp, tinh thông âm nhạc, giỏi đánh đàn và văn học. #Thăm hỏi# Tài nữ trứ danh thời hán",
            "img":`${_myPath}/meiren/detail/meiren_detail_28.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_28.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Thái Văn Cơ",
            "read":"",
            "introduce":"Thái Diễm, tự Chiêu Cơ sau đổi thành Văn Cơ. Người huyện Trần Lưu Đông Hán, là con gái của Thái Ung, một nhà văn học lớn thời Đông Hán. #Thăm hỏi# Tài nữ trứ danh thời Tam Quốc cuối nhà Hán",
            "img":`${_myPath}/meiren/detail/meiren_detail_29.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_29.png`, 
            "img_pifu":`${_myPath}/meiren/detail/meiren_pifu_29.png`,
            "img_pifu_chongai":""
        },
        {
            "name":"Trương Xuân Hoa",
            "read":"",
            "introduce":"Con gái của Túc Ấp Lệnh nước Ngụy, có đức hạnh từ nhỏ đã nổi tiếng thông minh bất phàm",
            "img":`${_myPath}/meiren/detail/meiren_detail_30.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_30.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Bột Nhi Nhiếp",
            "read":"",
            "introduce":"Đệ nhất Hãn Nhĩ Đôn, có địa vị rất cao, rất được kính trọng và được Thành Cát Tư Hãn sủng ái ",
            "img":`${_myPath}/meiren/detail/meiren_detail_31.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_31.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Nữ A Phòng",
            "read":"",
            "introduce":"Tương truyền, Tần Thủy  Hoàng vì sủng ái A Phòng mà xây dựng cung A Phòng cho nàng, nàng lập tức được thế nhân gọi là Nữ A Phòng",
            "img":`${_myPath}/meiren/detail/meiren_detail_32.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_32.png`, 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Tiết Linh Vân",
            "read":"",
            "introduce":"Con gái người giàu nhất kinh thành, tiểu thư khuê các, có hôn ước với bạn từ nhỏ, toàn tâm toàn ý với bạn. #Thăm hỏi# Con gái người giàu nhất Kinh Thành là Tiết Vạn Tam",
            "img":`${_myPath}/meiren/detail/meiren_detail_33.png`,
            "img_chongai":`${_myPath}/meiren/detail/meiren_chongai_33.png`, 
            "img_pifu":`${_myPath}/meiren/detail/meiren_pifu_33.png`, 
            "img_pifu_chongai":`${_myPath}/meiren/detail/meiren_pifuchongai_33.png`
        },
        {
            "name":"Tôn Thượng Hương",
            "read":"",
            "introduce":"Em gái Tôn Quyền vợ Lưu Bị cuối đời Đông Hán.#Hoạt động đặc biệt#Quận chúa Đông Ngô.",
            "img":`${_myPath}/meiren/detail/meiren_detail_34.png`,
            "img_chongai":"", 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Mao Tương",
            "read":"",
            "introduce":"Mỹ Nhân nước Việt thời kì Xuân Thu, mang vẻ đẹp chim sa cá lặn, là hình tượng nguyên thủy của “Trầm Ngư”, hóa thân của cái đẹp. #Phúc lợi VIP14# Mỹ Nhân nước Việt thời kì Xuân Thu.",
            "img":`${_myPath}/meiren/detail/meiren_detail_35.png`,
            "img_chongai":"", 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
        {
            "name":"Tức Tự",
            "read":"",
            "introduce":"Một trong những mỹ nhân nổi tiếng thời kỳ Xuân Thu, người đời gọi là “Tức Phu Nhân”, xinh đẹp như hoa như ngọc. #Phúc lợi VIP15#Một trong những mỹ nhân nổi tiếng thời kỳ Xuân Thu.",
            "img":`${_myPath}/meiren/detail/meiren_detail_36.png`,
            "img_chongai":"", 
            "img_pifu":"",
            "img_pifu_chongai":""
        },
    ]

}
export {roleDetails}
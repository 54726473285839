<template>
  <div class="news">
        <Navigation></Navigation> 
        <div class="bg"></div>
        <div class="mb_news_bg"></div>
        <img src="../assets/images/back_btn.png" alt="" class="back_btn" @click="goback">
        <img src="../assets/images/news_title.png" alt="" class="news_title">
        <div class="news_content">
            <img src="../assets/images/news_content.png" alt="" class="news_content_img">
            <img src="../assets/images/mb_news_content.png" alt="" class="news_content_img mb_news_content">
            <ul class="news_tabs">
                <li @click="toggleTab(1)" :class="{active:isActiveNewsTitle==1}">
                    <img src="../assets/images/news_tab1.png" alt="">
                </li>
                 <li @click="toggleTab(2)" :class="{active:isActiveNewsTitle==2}">
                    <img src="../assets/images/news_tab2.png" alt="">
                </li>
                 <li @click="toggleTab(3)" :class="{active:isActiveNewsTitle==3}">
                    <img src="../assets/images/news_tab3.png" alt="">
                </li>
            </ul>
            <div class="news_tags">
                <ul class="news_tag1">
                    <li v-for="(item,index) in currentList" :key="index" @click="goDetail(item)">
                        <span class="tag">{{item.tag}}</span>
                        <span class="title">{{item.title}}</span>
                        <span class="time">{{getNowTime(item.c_time)}}</span>
                    </li>
                </ul>
            </div>
            <div class="page">
                <img src="../assets/images/front.png" alt=""  @click="goPage('prev')">
                <div class="pageNum">
                    {{pageNum}}
                </div>
                <img src="../assets/images/back.png" alt=""  @click="goPage()">
            </div>
        </div>
  </div>
</template>

<script>
import Navigation from '../components/navigation'
import api from '@/api'
import getQueryString from '@/methods/getQueryString'
import getNowTime from '@/methods/getNowTime'

export default {
  name: 'news',
    components:{
      Navigation
  },
    data(){
        return{
            isActiveNewsTitle:0,
            pageNum:1,
            pageSize:5,
            newsListAll:[],
            newArr:[],
            tagId:1,
            tag:'Thông báo'
        }
    },
    computed: {
        // 当前列表
        currentList(){
            let newList = []
            let tag = this.tag
            this.newArr =  this.newsListAll.filter((e,i)=>{
                return e.tag == tag
            })
            return this.newArr.filter((e,i)=>{
                 return (this.pageNum-1) * this.pageSize <= i && i < this.pageNum*this.pageSize
            })
        },
    },
    mounted(){
        this.tagId = getQueryString('tagId') || 1
        this.isActiveNewsTitle = this.tagId
        if(this.tagId==1){
             this.tag = 'Thông báo'
        }else if(this.tagId==2){
            this.tag = 'Hoạt động'
        }else{
            this.tag = 'Công lược'
        }
    this.getNewsList()
    },
    methods:{ 
        getNowTime:getNowTime,
        goback:function(){
            this.$router.push("/")
        },
        // 切换tab
        toggleTab(tagId){
            this.isActiveNewsTitle = tagId 
            this.tagId = tagId
            if(this.tagId==1){
             this.tag = 'Thông báo'
            }else if(this.tagId==2){
                this.tag = 'Hoạt động'
            }else{
                this.tag = 'Công lược'
            }
            this.pageNum = 1
        },
        // 获取所有资讯
        getNewsList(){
            api.getNewsList()
            .then((res) => {
                let resData = res.data
                if(resData.code === 200){
                    this.newsListAll = resData.data
                }
            })
        },
        // 分页
        goPage(param){
            let totalPages = Math.ceil(this.newArr.length / this.pageSize)
            if(param === 'prev'){
                if(this.pageNum-1<=0) return false;
                this.pageNum = this.pageNum-1
            }else{
                if(this.pageNum+1>totalPages) return false;
                this.pageNum = this.pageNum+1
            }
        },
        // 详情
        goDetail:function(item){
            this.$router.push(`/newsDetail?tagId=${this.tagId}&newsId=${item.n_id}`)
        }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    .bg{
        background: url('../assets/images/bg.jpg') no-repeat;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .mb_news_bg{
        background: url('../assets/images/mb_news_bg.jpg') no-repeat;
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        display: none;
    }
    .news{
        width: 100%;
        height: 100%;
        text-align: left;
    }
    .back_btn{
        width:232px;
        margin-top: 150px;
        margin-left: 80px;
        cursor: pointer;
    }
    .news_title{
        width:600px;
        margin-top: 300px;
        margin-left:1200px;
    }
    .news_content{
        position: relative;
        top: -50px;
        left: 50%;
        transform: translateX(-50%); 
        width: 70%;
        text-align: center;
        .news_content_img{
            width: 100%;
        }
         .mb_news_content{
            display: none;
        }
        .news_tabs{
            position: absolute;
            width: 65%;
            top: 320px;
            left: 50%;
            transform: translateX(-50%);
            li{
                float: left;
                margin: 0 60px;
                cursor: pointer;
                background: url('../assets/images/normal.png') no-repeat;
                background-size: 100% 100%;
                width: 420px;
                padding: 20px 50px;
                img{
                    width:320px; 
                }
            }
            .active{
                background: url('../assets/images/light.png') no-repeat;
                background-size: 100% 100%;
            }
        }
        .news_tags{
            position: absolute;
            width: 65%;
            top: 500px;
            left: 50%;
            height: 800px;
            overflow: auto;
            transform: translateX(-50%);
            li{
                font-size: 60px;
                color:#edf1f2;
                font-weight: 500;
                padding-bottom: 25px;
                border-bottom: 1px solid #edf1f2;
                margin-bottom: 40px;
                cursor: pointer;
                .tag{
                    display: inline-block;
                    min-width: 250px;
                    padding: 5px 10px;
                    background-color: #7f2b1a;
                    border-radius: 10px;
                    color: #fff;
                }
                .title{
                    display: inline-block;
                    width: 800px;
                    margin: 0 30px;
                    text-overflow:ellipsis; 
                    white-space:nowrap; 
                    overflow:hidden;
                    vertical-align: bottom;
                }
                
            }
        }
        .page{
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            img{
                width: 120px;
                vertical-align: sub;
                cursor: pointer;
            }
            .pageNum{
                display:inline-block;
                width: 100px;
                height: 100px;
                color: #000;
                font-size: 80px;
                margin: 0 400px;
            }
        }
    }
    
// 移动端
@media screen and (max-width: 750px) {
    .news_tags{
        li{
            font-size: 100px;
        }
    }
    .news_content .page .pageNum{
        font-size: 10px;
        width: 120px;
        height: 120px;
    }
    .bg{
        display: none;
    }
    .mb_news_bg{
        display: block;
    }
    .back_btn{
        width: 400px;
        margin-top: 400px;
    }
    .news_title{
        width: 1000px;
        margin-left: 900px;
        margin-top: 600px;
    }
    .news_content{
        width: 90%;
        .news_content_img{
           display: none;
        }
        .mb_news_content{
            display: block;
        }
        .news_tabs{
            width: 75%;
            transform: translateX(-45%);
            top: 800px;
            li{
                width: 600px;
                height: 250px;
                line-height: 250px;
                margin: 0 80px;
                font-size: 100px;
                img{
                    width: 500px;
                }
            }
        }
        .news_tags{
            top: 1300px;
            height: 2900px;
            li{
                font-size: 50px;
                margin-bottom:120px;
                text-align: left;
                .tag{
                    display: block;
                    width: 800px;
                    height: 180px;
                    margin-bottom: 50px;
                    text-align: center;
                    font-size: 120px;
                }
                .title{
                    font-size: 120px;
                    display: inline-block;
                    width: 1400px;
                    margin-right: 50px;
                    // display: block;
                }
                .time{
                    font-size: 100px;
                }
            }
        }
        .page{
            z-index: 1;
            bottom: -80px;
            img{
                width: 300px;
            }
            .pageNum{
                font-size: 200px;
                margin: 0 460px;
            }
        }
    }
}
</style>
